import React, { useState, useEffect } from 'react';
import { TableEmptyState, TableHeader, TableNoMatchState } from '@/presentation/components/commons/common-components';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { CollectionPreferences, Container, Pagination, PropertyFilter, Table } from '@cloudscape-design/components-themed/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { getFilterCounterText } from '@/presentation/components/commons/tableCounterStrings';
import { getFieldOnChange } from '@/presentation/components';
import { PATH_PROFILES, TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import { useNavigate } from 'react-router-dom';
import { autoResizeOption, makePropertyFilterI18nStrings } from '@/presentation/pages';
import { IdentitiesProfilesDetailsFilteringProperties, IdentitiesProfilesDetailsTablePageSizeOptions, IdentitiesProfilesDetailsTablePreferences, IdentitiesProfilesDetailsTableVisibleContentOptions, IdentitiesProfilesFormColumnDefinitions } from '@/presentation/pages/identities/identity-profiles-config';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
import { PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import TableContainer from '@/presentation/components/table/table-container';
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from '@/presentation/storage/misc-storage';
import { fetchUniquePropertyFilters } from '@/presentation/common-utils/common-utils';
const IdentitiesProfiles = (props) => {
    var _a, _b, _c;
    const navigate = useNavigate();
    const ref = React.useRef(null);
    const [selectedItems, setSelectedItems] = useState(props.info.profiles.attachedProfiles);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.IDENTITIES_CREATE_EDIT_PROFILES_LIST, IdentitiesProfilesDetailsTablePreferences);
    const getSessionPropertyFilter = GetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.IDENTITIES_FORM_PROFILES_LIST);
    const getSessionPropertySorting = GetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.IDENTITIES_FORM_PROFILES_LIST);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : IdentitiesProfilesFormColumnDefinitions[0],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(props.profiles, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: IdentitiesProfilesDetailsFilteringProperties,
            empty: React.createElement(TableEmptyState, { resourceName: "Profiles", createButtonText: "Create Profile", createOnClick: () => navigate(PATH_PROFILES.CREATE) }),
            noMatch: (React.createElement(TableNoMatchState, { onClearFilter: () => {
                    actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                } }))
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: defaultSortingState },
        selection: {}
    });
    const [renderCount, setRenderCount] = useState(1);
    const onProfilesTableChange = getFieldOnChange('table', 'attachedProfiles', props.onChange);
    useEffect(() => {
        var _a;
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            const hashValue = (_a = window.location.hash) === null || _a === void 0 ? void 0 : _a.substring(1);
            if (hashValue === 'profiles') {
                document.getElementById('profiles').scrollIntoView();
            }
        }
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    const handleFiltersOnChange = ({ detail }) => {
        const filteredDetail = fetchUniquePropertyFilters(detail);
        SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.IDENTITIES_FORM_PROFILES_LIST, filteredDetail);
        actions.setPropertyFiltering(filteredDetail);
    };
    return (React.createElement("div", { ref: ref, id: "profiles" },
        React.createElement(Container, null,
            React.createElement(TableContainer, { key: renderCount },
                React.createElement(Table, Object.assign({}, collectionProps, { columnDefinitions: IdentitiesProfilesFormColumnDefinitions, items: items, selectionType: "multi", resizableColumns: (_b = (_a = preferences === null || preferences === void 0 ? void 0 : preferences.custom) === null || _a === void 0 ? void 0 : _a.autoResize) !== null && _b !== void 0 ? _b : false, variant: "embedded", selectedItems: selectedItems, onSelectionChange: event => {
                        setSelectedItems(event.detail.selectedItems);
                        onProfilesTableChange(event);
                    }, onSortingChange: ({ detail }) => {
                        SetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.IDENTITIES_FORM_PROFILES_LIST, detail);
                        actions.setSorting(detail);
                    }, pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), filter: React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Profiles' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringProperties: IdentitiesProfilesDetailsFilteringProperties, filteringOptions: [], onChange: handleFiltersOnChange })), header: React.createElement(TableHeader, { title: "Profiles", selectedItems: selectedItems, totalItems: props.profiles }), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                            setPreferences(detail);
                            setRenderCount(renderCount + 1);
                        }, pageSizePreference: {
                            title: 'Page Size',
                            options: IdentitiesProfilesDetailsTablePageSizeOptions
                        }, wrapLinesPreference: {
                            label: 'Wrap Lines',
                            description: 'If checked, lines will be wrapped to display all of the text'
                        }, visibleContentPreference: {
                            title: 'Visible Columns',
                            options: IdentitiesProfilesDetailsTableVisibleContentOptions
                        } }) }, (((_c = preferences === null || preferences === void 0 ? void 0 : preferences.custom) === null || _c === void 0 ? void 0 : _c.autoResize) ? autoResizeOption : {})))))));
};
export default IdentitiesProfiles;
