import React, { useEffect, useState } from 'react';
import { CollectionPreferences, Pagination, PropertyFilter, Table } from '@cloudscape-design/components-themed/components';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
import { useColumnWidths } from '@/presentation/components/commons/use-column-widths';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { getFilterCounterText } from '@/presentation/components/commons/tableCounterStrings';
import { TableHeader, TableNoMatchState } from '@/presentation/components/commons/common-components';
import TableContainer from '@/presentation/components/table/table-container';
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from '@/presentation/storage/misc-storage';
import { fetchUniquePropertyFilters } from '@/presentation/common-utils/common-utils';
const InmemoryTable = (props) => {
    var _a, _b, _c;
    const [renderCount, setRenderCount] = useState(1);
    const [tableData, setTableData] = useState((props === null || props === void 0 ? void 0 : props.items) || []);
    const [selectedItems, setSelectedItems] = useState([]);
    const [columnDefinitions] = useColumnWidths(props.columnWidthStorageKey, props.columnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(props.preferencesStorageKey, props.defaultPreferences);
    const getSessionPropertyFilter = GetPropertyFilters(props.propertyFilterStorageName);
    const getSessionPropertySorting = GetTableSortingColumn(props.propertySortingStorageName);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : (_a = props.columnDefinitions) === null || _a === void 0 ? void 0 : _a[0],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(tableData, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: props.propertyFilterFilteringProperties,
            empty: props.tableEmptyState,
            noMatch: React.createElement(TableNoMatchState, { onClearFilter: () => { actions.setPropertyFiltering({ tokens: [], operation: 'and' }); } }),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: defaultSortingState },
        selection: {}
    });
    useEffect(() => {
        setSelectedItems([]);
        setTableData((props === null || props === void 0 ? void 0 : props.items) || []);
    }, [props === null || props === void 0 ? void 0 : props.items]);
    return React.createElement(TableContainer, { key: renderCount },
        React.createElement(Table, Object.assign({}, collectionProps, props, { resizableColumns: true, columnDefinitions: columnDefinitions, visibleColumns: preferences.visibleContent, items: items, ariaLabels: props.ariaLabels, wrapLines: preferences.wrapLines, selectedItems: selectedItems, onSelectionChange: event => {
                setSelectedItems(event.detail.selectedItems);
                props.selectionChangeCallback(event.detail.selectedItems, event);
            }, onSortingChange: ({ detail }) => {
                if (props.propertyFilterStorageName)
                    SetTableSortingColumn(props.propertySortingStorageName, detail);
                actions.setSorting(detail);
            }, header: React.createElement(TableHeader, { variant: props.tableHeaderVariant, title: props.resourceName, description: (_b = props.headerDescription) !== null && _b !== void 0 ? _b : '', selectedItems: selectedItems, totalItems: props.items, actionButtons: props.actionButtons, singleSelect: (props === null || props === void 0 ? void 0 : props.selectionType) === 'single' }), filter: React.createElement("div", { className: 'list-property-filter' }, (_c = props === null || props === void 0 ? void 0 : props.propertyFilterPrefixNode) !== null && _c !== void 0 ? _c : null,
                React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: props.propertyFilterI18nStrings, countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: props.propertyFilterFilteringOptions, filteringProperties: props.propertyFilterFilteringProperties, onChange: ({ detail }) => {
                        const filteredDetail = fetchUniquePropertyFilters(detail);
                        if (props.propertyFilterStorageName)
                            SetPropertyFilters(props.propertyFilterStorageName, filteredDetail);
                        actions.setPropertyFiltering(filteredDetail);
                    } }))), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: props.paginationLabels })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                    setPreferences(detail);
                    setRenderCount(renderCount + 1);
                }, pageSizePreference: {
                    title: 'Page Size',
                    options: props.pageSizeOptions
                }, wrapLinesPreference: {
                    label: 'Wrap Lines',
                    description: 'If checked, lines will be wrapped to display all of the text'
                }, visibleContentPreference: {
                    title: 'Visible Columns',
                    options: props.visibleContentOptions
                } }) })));
};
export default InmemoryTable;
