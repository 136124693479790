import React, { useEffect, useState } from 'react';
import { useSettingsHandlers } from '@/presentation/providers/settings-handlers-provider';
import { getContainerHeight, makeInvalidFormErrorMessage } from '@/presentation/common-utils/common-utils';
import { Button, Container, ContentLayout, Form, Header, SpaceBetween, FormField, TextContent, Icon, Box } from '@cloudscape-design/components-themed/components';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '@/presentation/providers/notifications-provider';
import ContentWrapper from '@/presentation/components/content-wrapper';
import { getOrgIDFromToken } from '@/lib/auth0/utils';
import { useAuth0 } from '@/lib/auth0';
import { makeNotification } from '@/presentation/pages';
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import CodeEditor from "@/presentation/components/code-editor/code-editor";
import { useIsMounted } from '@/presentation/hooks';
import { useAppContext } from "@/presentation/providers/app-context-provider";
import { PATHS } from '@/presentation/common-utils/constants';
import { SETTINGS_TABS } from '../settings-types';
const SettingsOrganizationPolicyFunctionsEdit = () => {
    const [editorLoading, setEditorLoading] = useState(true);
    const { pushNotifications, dismissNotification } = useNotifications();
    const [editorHeight, setEditorHeight] = useState(400);
    const [currentRef, setCurrentRef] = useState(null);
    const [constraintText, setConstraintText] = useState(null);
    const [errorText, setErrorText] = useState(null);
    const ref = React.useRef(null);
    const isMounted = useIsMounted();
    const errorRef = React.useRef(null);
    const { settings } = useSettingsHandlers();
    const settingsHandler = settings;
    const navigate = useNavigate();
    const [configValue, setConfigValue] = useState('');
    const [globalPolicyFunc, setGlobalPolicyFunc] = useState('');
    const [orgID, setOrgID] = useState(0);
    const { user, rawToken } = useAuth0();
    const { setIsFormDirty, setDirtyFormResourceName } = useAppContext();
    const navigateUrl = `${PATHS.SETTINGS}?tabId=${SETTINGS_TABS.ORG_POLICY_FUNCTIONS}`;
    const updateEditorHeight = (updatedHeight) => {
        var _a;
        const removeCreateButtonDivSize = 100;
        // Approximate padding size around the create button container in pixels
        const currentErrorHeight = ((_a = document.getElementById("editorCustomErrorContent")) === null || _a === void 0 ? void 0 : _a.offsetHeight) || 0;
        const updatedErrorContainerHeight = currentErrorHeight > 100 ? 100 : currentErrorHeight;
        const newHeight = updatedHeight - removeCreateButtonDivSize - updatedErrorContainerHeight;
        setEditorHeight(newHeight);
    };
    useEffect(() => {
        setCurrentRef(ref === null || ref === void 0 ? void 0 : ref.current);
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    useEffect(() => {
        // The DOM element is accessible here.
        if (currentRef) {
            getContainerHeight('settingsEditOrgPolicyEditor', updateEditorHeight, editorHeight);
        }
    }, [currentRef]);
    useEffect(() => {
        if ((constraintText || errorText) && errorRef) {
            getContainerHeight('settingsEditOrgPolicyEditor', updateEditorHeight, editorHeight);
        }
    }, [errorRef === null || errorRef === void 0 ? void 0 : errorRef.current, errorText, constraintText]);
    useEffect(() => {
        settingsHandler.listGlobalPolicyFunctions().then((globFuncs) => {
            var _a, _b;
            if (isMounted.current) {
                setGlobalPolicyFunc(((_b = (_a = globFuncs === null || globFuncs === void 0 ? void 0 : globFuncs.GlobalPolicyFunctions) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.function) || '');
            }
        })
            .catch(console.error)
            .finally(() => {
            settingsHandler.listOrganizationPolicyFunctions().then((orgFuncs) => {
                var _a, _b;
                setConfigValue(((_b = (_a = orgFuncs === null || orgFuncs === void 0 ? void 0 : orgFuncs.OrganizationPolicyFunctions) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.function) || '');
            }).finally(() => setEditorLoading(false));
        });
    }, []);
    useEffect(() => {
        setOrgID(getOrgIDFromToken(rawToken));
    }, [user]);
    const makeSuccessNotification = () => {
        const msg = 'Organization Policy Functions updated successfully';
        return makeNotification("success", msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to proceed with this request. Please try again later.';
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const hanldeDirtyFormChange = (value = true) => {
        setIsFormDirty(value);
        setDirtyFormResourceName(value ? 'policy' : null);
    };
    const handleOnSubmit = () => {
        settingsHandler.createAndUpdateOrganizationPolicyFunction(orgID, configValue)
            .then((result) => {
            var _a;
            if (((_a = result === null || result === void 0 ? void 0 : result.insert_OrganizationPolicyFunctions_one) === null || _a === void 0 ? void 0 : _a.function) !== undefined) {
                pushNotifications(makeSuccessNotification());
                hanldeDirtyFormChange(false);
                navigate(navigateUrl);
            }
            else {
                pushNotifications(makeErrorNotification('Error in updating policy functions'));
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        });
    };
    const handleCodeChange = (regoCode) => {
        setConfigValue(regoCode);
        hanldeDirtyFormChange(true);
    };
    const validatePolicy = (isSubmit = false) => {
        if (isMounted.current) {
            setEditorLoading(true);
            const toBeValidatedPolicy = globalPolicyFunc + '\r\n' + configValue;
            settingsHandler.validatePolicyConfig(toBeValidatedPolicy).then((validationResult) => {
                var _a, _b, _c, _d;
                if (isMounted.current) {
                    setEditorLoading(false);
                    if (validationResult === null || validationResult === void 0 ? void 0 : validationResult.error) {
                        const errMsg = (((_a = validationResult === null || validationResult === void 0 ? void 0 : validationResult.data) === null || _a === void 0 ? void 0 : _a.message) && ((_b = validationResult === null || validationResult === void 0 ? void 0 : validationResult.data) === null || _b === void 0 ? void 0 : _b.message) !== '') ? (React.createElement("pre", null, (_c = validationResult === null || validationResult === void 0 ? void 0 : validationResult.data) === null || _c === void 0 ? void 0 : _c.message)) : 'Please provide an organization policy';
                        setConstraintText(null);
                        setErrorText(errMsg);
                        if (isSubmit) {
                            pushNotifications(makeErrorNotification(makeInvalidFormErrorMessage('organizational policy functions', 'update')));
                        }
                    }
                    else {
                        const message = (React.createElement(TextContent, null,
                            React.createElement("p", null,
                                " ",
                                React.createElement(Icon, { name: "status-positive", variant: "success" }),
                                " ",
                                React.createElement("strong", null,
                                    React.createElement("small", null,
                                        " ",
                                        ((_d = validationResult === null || validationResult === void 0 ? void 0 : validationResult.data) === null || _d === void 0 ? void 0 : _d.message) || 'Organizational Policy is valid',
                                        " ")),
                                " ")));
                        setErrorText(null);
                        setConstraintText(message);
                        if (isSubmit) {
                            handleOnSubmit();
                        }
                    }
                }
            });
        }
    };
    return React.createElement(ContentWrapper, { testid: "proxy-create-deployment-page" },
        React.createElement(ContentLayout, { header: React.createElement(Header, { variant: "h1" }, "Edit Organization Policy Functions") },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Form, { actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                        React.createElement(Button, { formAction: "none", variant: "link", onClick: () => navigate(navigateUrl) }, "Cancel"),
                        React.createElement(Button, { variant: "primary", onClick: () => {
                                if (configValue) {
                                    validatePolicy(true);
                                }
                                else {
                                    handleOnSubmit();
                                }
                            } }, "Update")) },
                    React.createElement(Container, { header: React.createElement(Header, { variant: "h2", actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "l" },
                                React.createElement(Button, { variant: "normal", disabled: !configValue, onClick: () => validatePolicy() }, "Validate")) }, "Policy") },
                        React.createElement(SpaceBetween, { direction: "vertical", size: "l" },
                            React.createElement("div", { ref: ref, id: "settingsEditOrgPolicyEditor" },
                                React.createElement(FormField, null,
                                    React.createElement(CodeEditor, { loading: editorLoading, code: configValue, onChange: handleCodeChange, height: editorHeight, heightType: 'px', readOnly: false, language: 'rego' }),
                                    React.createElement("div", { style: { maxHeight: '100px', overflow: "auto", marginTop: '10px' } },
                                        React.createElement(Box, { float: "left", color: "text-status-error" },
                                            React.createElement("div", { className: "flex" },
                                                errorText && (React.createElement("div", { className: "mr-2" },
                                                    React.createElement(Icon, { name: "status-warning", size: "small", variant: "error" }))),
                                                React.createElement("div", { ref: errorRef, id: "editorCustomErrorContent" }, errorText || constraintText))))))))))));
};
export default SettingsOrganizationPolicyFunctionsEdit;
