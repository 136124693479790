import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useProxiesHandlers } from "@/presentation/providers";
import { useParams } from "react-router-dom";
import { formatExistingTags, getLogoAsUrl, getProviderDescription } from "../common";
import ContentWrapper from "@/presentation/components/content-wrapper";
import { HeaderStrings } from "@/presentation/pages/proxies/proxies-types";
import ProxiesForm from "@/presentation/pages/proxies/proxies-form";
import { ContentLayout, Header } from "@cloudscape-design/components-themed/components";
import moment from 'moment';
import { useIsMounted } from '@/presentation/hooks';
import { useErrorBoundaryContext } from "@/presentation/providers/error-boundary-provider";
const ProxiesFormWrapper = (props) => {
    const { setErrorStatus } = useErrorBoundaryContext();
    const [loading, setLoading] = useState(false);
    const [listIdentities, setListIdentities] = useState([]);
    const [domainACLs, setDomainACLs] = useState([]);
    const [providersList, setProvidersList] = useState();
    const [proxyTagsAndIdentities, setProxyTagsAndIdentities] = useState();
    const { proxies, identities, providers, domainACL } = useProxiesHandlers();
    const proxiesHandler = proxies;
    const identitiesHandler = identities;
    const providersHandler = providers;
    const domainACLHandler = domainACL;
    const params = useParams();
    const [stepInfo, setStepInfo] = useState(undefined);
    const isMounted = useIsMounted();
    const makeLogLevel = (isDebug) => {
        if (isDebug) {
            return {
                label: 'Debug',
                value: 'DEBUG'
            };
        }
        return {
            label: 'Info',
            value: 'INFO'
        };
    };
    const fetchDomainHandlerList = () => {
        domainACLHandler.list().then(listAcls => {
            if (isMounted.current) {
                setDomainACLs(domainACLHandler.getFormattedDomainACLs(listAcls));
            }
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            providersHandler.listEntity().then((providers) => {
                if (isMounted.current) {
                    setProvidersList(providers);
                }
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                if (isMounted.current) {
                    setLoading(false);
                }
            });
        });
    };
    const fetchIdentitiesAndProviders = (proxy) => {
        if (isMounted.current) {
            setLoading(true);
        }
        identitiesHandler.listEntity().then((identities) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17;
            const proxyIdentities = (_c = (_b = (_a = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _a === void 0 ? void 0 : _a.ProxyIdentities) === null || _b === void 0 ? void 0 : _b.map(identity => identity === null || identity === void 0 ? void 0 : identity.identity_id)) === null || _c === void 0 ? void 0 : _c.filter(id => !!id);
            const defaultIdentityID = String((_f = (_e = (_d = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _d === void 0 ? void 0 : _d.ProxySettings[0]) === null || _e === void 0 ? void 0 : _e.default_identity_id) !== null && _f !== void 0 ? _f : '');
            const domainACLID = String((_l = (_k = (_j = (_h = (_g = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _g === void 0 ? void 0 : _g.ProxyDomainAcls) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.DomainAcl) === null || _k === void 0 ? void 0 : _k.id) !== null && _l !== void 0 ? _l : '');
            const domainACLName = String((_r = (_q = (_p = (_o = (_m = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _m === void 0 ? void 0 : _m.ProxyDomainAcls) === null || _o === void 0 ? void 0 : _o[0]) === null || _p === void 0 ? void 0 : _p.DomainAcl) === null || _q === void 0 ? void 0 : _q.name) !== null && _r !== void 0 ? _r : '');
            const defaultIdentityName = String((_v = (_u = (_t = (_s = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _s === void 0 ? void 0 : _s.ProxySettings[0]) === null || _t === void 0 ? void 0 : _t.Identity) === null || _u === void 0 ? void 0 : _u.name) !== null && _v !== void 0 ? _v : '');
            const formattedIdentities = identitiesHandler.getSelectableInMemoryFormattedEntities(identities, proxyIdentities, defaultIdentityID);
            if (isMounted.current) {
                setListIdentities(formattedIdentities);
            }
            const selectedProviders = ((_w = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _w === void 0 ? void 0 : _w.ProxyProviders.length) > 0 ? proxy.Proxies_by_pk.ProxyProviders
                .filter(provider => provider === null || provider === void 0 ? void 0 : provider.enabled)
                .map(provider => {
                var _a, _b, _c;
                return {
                    label: (_a = provider === null || provider === void 0 ? void 0 : provider.Provider) === null || _a === void 0 ? void 0 : _a.name,
                    value: String(provider === null || provider === void 0 ? void 0 : provider.provider_id),
                    iconUrl: getLogoAsUrl((_b = provider === null || provider === void 0 ? void 0 : provider.Provider) === null || _b === void 0 ? void 0 : _b.name),
                    description: getProviderDescription((_c = provider === null || provider === void 0 ? void 0 : provider.Provider) === null || _c === void 0 ? void 0 : _c.name)
                };
            }) : [];
            const existingTags = formatExistingTags((_x = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _x === void 0 ? void 0 : _x.tags);
            const currentTimestamp = moment().format('YYYYMMDDHHmmss');
            const actualName = (_z = (_y = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _y === void 0 ? void 0 : _y.name) !== null && _z !== void 0 ? _z : '';
            const modifiedName = props.isClone ? `${actualName}-${currentTimestamp}` : actualName;
            const proxiesCreateDefaultStepInfo = {
                details: {
                    id: (_1 = (_0 = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _0 === void 0 ? void 0 : _0.id) !== null && _1 !== void 0 ? _1 : 0,
                    name: modifiedName,
                    description: (_3 = (_2 = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _2 === void 0 ? void 0 : _2.description) !== null && _3 !== void 0 ? _3 : '',
                    providers: selectedProviders,
                    nonProviderTraffic: (_7 = (_6 = (_5 = (_4 = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _4 === void 0 ? void 0 : _4.ProxySettings) === null || _5 === void 0 ? void 0 : _5[0]) === null || _6 === void 0 ? void 0 : _6.allow_noncloud_traffic) !== null && _7 !== void 0 ? _7 : false,
                    domainACLs: !domainACLID ? {} : {
                        label: domainACLName,
                        value: domainACLID
                    },
                    defaultMode: proxiesHandler.makeDefaultMode((_10 = (_9 = (_8 = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _8 === void 0 ? void 0 : _8.ProxySettings) === null || _9 === void 0 ? void 0 : _9[0]) === null || _10 === void 0 ? void 0 : _10.default_mode),
                    learningMode: ((_13 = (_12 = (_11 = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _11 === void 0 ? void 0 : _11.ProxySettings) === null || _12 === void 0 ? void 0 : _12[0]) === null || _13 === void 0 ? void 0 : _13.learning_mode) || false,
                    logLevel: makeLogLevel((_17 = (_16 = (_15 = (_14 = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _14 === void 0 ? void 0 : _14.ProxySettings) === null || _15 === void 0 ? void 0 : _15[0]) === null || _16 === void 0 ? void 0 : _16.debug) !== null && _17 !== void 0 ? _17 : false)
                },
                identities: {
                    attachedIdentities: formattedIdentities.filter(identity => identity.isSelected),
                    defaultIdentity: !defaultIdentityID ? {} : {
                        label: defaultIdentityName,
                        value: defaultIdentityID
                    }
                },
                tags: {
                    editorTags: [...existingTags]
                }
            };
            if (isMounted.current) {
                setStepInfo(proxiesCreateDefaultStepInfo);
            }
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            fetchDomainHandlerList();
        });
    };
    useEffect(() => {
        if ((params === null || params === void 0 ? void 0 : params.proxyID) && parseInt(params === null || params === void 0 ? void 0 : params.proxyID, 10) !== 0) {
            const vars = {
                proxy_id: parseInt(params === null || params === void 0 ? void 0 : params.proxyID, 10)
            };
            let proxyAndTagsAndIdentities;
            proxiesHandler.fetchProxy(vars).then((proxy) => {
                var _a, _b;
                if (isMounted.current) {
                    if (proxy && ((_a = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _a === void 0 ? void 0 : _a.id) && ((_b = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _b === void 0 ? void 0 : _b.status) === 'READY') {
                        proxyAndTagsAndIdentities = proxy;
                        setProxyTagsAndIdentities(proxy);
                    }
                    else {
                        setErrorStatus(true);
                    }
                }
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                fetchIdentitiesAndProviders(proxyAndTagsAndIdentities);
            });
        }
        else {
            fetchIdentitiesAndProviders(undefined);
        }
    }, []);
    if ((props.isEdit && proxyTagsAndIdentities === undefined) || !stepInfo) {
        return React.createElement(Skeleton, { count: 30 });
    }
    if (loading) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return React.createElement(ContentWrapper, { testid: "proxy-create-edit-page" },
        React.createElement(ContentLayout, { header: React.createElement(Header, { variant: "h1" }, props.isCreate || props.isClone ? HeaderStrings.create : HeaderStrings.edit) },
            React.createElement(ProxiesForm, { isCreate: props.isCreate, isEdit: props.isEdit, isClone: props.isClone, proxyAndTagsAndIdentities: proxyTagsAndIdentities, stepInfo: stepInfo, identities: listIdentities, domainACLs: domainACLs, providersList: providersList })));
};
export default ProxiesFormWrapper;
