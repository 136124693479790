import React, { useEffect, useState } from "react";
import { Button } from "@cloudscape-design/components-themed/components";
import { ReactComponent as AccessDenied } from "@/presentation/assets/img/pages/fatal-error.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faRightFromBracket, } from "@fortawesome/free-solid-svg-icons";
import Support from "@/presentation/components/support/support";
import { getContainerHeight } from "@/presentation/common-utils/common-utils";
const ErrorPageWrapper = ({ handleLogout, reloadPage, isHeightRequired }) => {
    const windowLocationSearch = decodeURIComponent(window.location.search);
    const searchParams = new URLSearchParams(windowLocationSearch);
    const errorDescription = searchParams.get("error_description");
    const [isSupportReqLoading, setIsSupportReqLoading] = useState(false);
    const [containerHeight, setContainerHeight] = useState({});
    const [currentRef, setCurrentRef] = useState(null);
    const ref = React.useRef(null);
    useEffect(() => {
        setCurrentRef(ref === null || ref === void 0 ? void 0 : ref.current);
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    const handleContainerHeight = (val) => {
        setContainerHeight({ height: val });
    };
    useEffect(() => {
        // The DOM element is accessible here.
        if (currentRef) {
            const docBodyElem = document.querySelector("body");
            docBodyElem.classList.add("bg-neutral-200");
            if (isHeightRequired) {
                getContainerHeight('errorPageWrapper', handleContainerHeight, 400);
            }
        }
    }, [currentRef]);
    if (isSupportReqLoading) {
        return React.createElement(Support, null);
    }
    return (React.createElement("div", { ref: ref, id: "errorPageWrapper", className: "max-w-full text-center bg-neutral-200 h-full flex flex-row justify-center", style: Object.assign({}, containerHeight) },
        React.createElement("div", { className: "flex flex-col justify-center items-center gap-1 my-12" },
            React.createElement(AccessDenied, { className: "w-10/12 lg:w-auto h-5/6 lg:h-auto " }),
            React.createElement("div", { className: "text-center mx-auto mt-12" },
                React.createElement("h1", { className: "text-3xl lg:text-4xl font-bold my-3" },
                    React.createElement("span", { className: "error-page-oops" }, "Oops!"),
                    React.createElement("span", { className: "error-page-wrong px-1" }, "Something went wrong.")),
                errorDescription === "Access denied" ? (React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement("span", { className: "px-1" }, "Contact your Kivera Organization Admin or Support")),
                    React.createElement("div", { className: "my-5" },
                        React.createElement(Button, { variant: "primary", onClick: () => handleLogout(), iconSvg: React.createElement(FontAwesomeIcon, { icon: faRightFromBracket, size: "2xs" }) }, "Logout")))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement("span", { className: "px-1" }, "Contact your Kivera Organization Admin or"),
                        React.createElement("a", { href: "#", tabIndex: 0, onClick: () => {
                                setIsSupportReqLoading(true);
                            }, onKeyPress: () => {
                                setIsSupportReqLoading(true);
                            }, rel: "noreferrer", className: "text-[#cf4dff]", style: { cursor: 'pointer' } }, "Support")),
                    React.createElement("div", { className: "my-5" },
                        React.createElement(Button, { variant: "primary", onClick: () => {
                                if (reloadPage) {
                                    window.location.reload();
                                }
                                else {
                                    window.location.href = "/";
                                }
                            }, iconSvg: React.createElement(FontAwesomeIcon, { icon: faArrowsRotate, size: "2xs" }) }, "Try again"))))))));
};
export default ErrorPageWrapper;
