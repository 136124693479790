import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIdentitiesHandlers } from '@/presentation/providers';
import { useNotifications } from '@/presentation/providers/notifications-provider';
import { Alert, Badge, Box, Button, ColumnLayout, Container, ContentLayout, Header, Icon, SpaceBetween } from '@cloudscape-design/components-themed/components';
import { IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH, IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE, IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT, IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY, PATHS, TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import Skeleton from 'react-loading-skeleton';
import ContentWrapper from '@/presentation/components/content-wrapper';
import DeleteModal from '@/presentation/components/modal/delete-modal';
import { IdentitiesDeleteStrings, IdentityDeleteShowProp } from '@/presentation/pages/identities/identities-types';
import { IdentitiesProfilesColumnDefinitions, IdentitiesProfilesDetailsTablePreferences, IdentitiesProfilesDetailsTableVisibleContentOptions, IdentitiesProfilesDetailsTablePageSizeOptions, IdentitiesProfilesDetailsFilteringProperties, IDENTITIES_VIEW_CIDR_RANGES_COLUMN_DEFINITIONS, IDENTITIES_VIEW_CLOUD_TENANTS_COLUMN_DEFINITIONS, IDENTITIES_VIEW_USER_IDENTITIES_COLUMN_DEFINITIONS } from '@/presentation/pages/identities/identity-profiles-config';
import { makeNotification, makePropertyFilterI18nStrings, getLogoAsUrl, } from '@/presentation/pages';
import EyeOpenButtonIcon from '@/presentation/components/button-icons/eye-open-button-icon';
import EyeClosedButtonIcon from '@/presentation/components/button-icons/eye-closed-button-icon';
import { TableHeader } from '@/presentation/components/commons/common-components';
import { PREFERENCES_STORAGE_KEY, COLUMN_WIDTH_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import InmemoryDetailsTable from '@/presentation/components/table/inmemory-details-table';
import ShowTags from '@/presentation/components/tags/show-tags';
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import _ from "lodash";
import { getIdentifierLabel } from "./constants";
import ViewTable from "@/presentation/components/table/view-table";
import { useIsMounted } from '@/presentation/hooks';
import { useErrorBoundaryContext } from '@/presentation/providers/error-boundary-provider';
import ExternalLink from '@/presentation/components/links/external-link';
import { SETTINGS_TABS } from '../settings/settings-types';
const NotFoundTextSpan = (text = '') => {
    return React.createElement("span", { style: { color: "#D91515" } }, `${text} [Not Found]`);
};
const indentifierLabelObject = getIdentifierLabel();
const IdentityDetails = (props) => {
    var _a, _b, _c, _d;
    return React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Identity Details") },
        React.createElement(ColumnLayout, { columns: 4, variant: "text-grid" },
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "ID"),
                React.createElement(Box, null, (_a = props === null || props === void 0 ? void 0 : props.identity) === null || _a === void 0 ? void 0 : _a.id)),
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Description"),
                React.createElement(Box, null, (_b = props === null || props === void 0 ? void 0 : props.identity) === null || _b === void 0 ? void 0 : _b.description)),
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Attached Profiles (#)"),
                React.createElement(Box, null, (_c = props === null || props === void 0 ? void 0 : props.identity) === null || _c === void 0 ? void 0 : _c.attachedProfilesCount)),
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Attached Rules (#)"),
                React.createElement(Box, null, (_d = props === null || props === void 0 ? void 0 : props.identity) === null || _d === void 0 ? void 0 : _d.attachedRulesCount))));
};
const IdentitySettings = (props) => {
    var _a, _b, _c, _d;
    const [showKey, setShowKey] = useState(false);
    const renderPassword = () => {
        var _a, _b, _c, _d;
        return React.createElement(SpaceBetween, { size: "l" },
            React.createElement(ColumnLayout, { columns: 3, variant: "text-grid" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Identifier"),
                    React.createElement(Box, null,
                        React.createElement(Badge, { color: 'grey' }, indentifierLabelObject[(_a = props === null || props === void 0 ? void 0 : props.identity) === null || _a === void 0 ? void 0 : _a.type]))),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Username"),
                    React.createElement(Box, null, (_c = (_b = props === null || props === void 0 ? void 0 : props.identity) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '')),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Password"),
                    React.createElement(Box, null, showKey
                        ? React.createElement(React.Fragment, null, (_d = props === null || props === void 0 ? void 0 : props.identity) === null || _d === void 0 ? void 0 :
                            _d.apiKey,
                            React.createElement(EyeOpenButtonIcon, { ariaLabel: 'show-api-key-button-icon', onClick: () => setShowKey(!showKey) }))
                        : React.createElement(React.Fragment, null,
                            '******************',
                            React.createElement(EyeClosedButtonIcon, { ariaLabel: 'dont-show-api-key-button-icon', onClick: () => setShowKey(!showKey) }))))));
    };
    const renderCidrRanges = () => {
        var _a, _b, _c, _d;
        const ranges = (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.identity) === null || _a === void 0 ? void 0 : _a.cidrRange) === null || _b === void 0 ? void 0 : _b.map(val => {
            return {
                range: val
            };
        })) !== null && _c !== void 0 ? _c : [];
        return React.createElement(SpaceBetween, { size: "l" },
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Identifier"),
                React.createElement(Box, null,
                    React.createElement(Badge, { color: 'grey' }, indentifierLabelObject[(_d = props === null || props === void 0 ? void 0 : props.identity) === null || _d === void 0 ? void 0 : _d.type]))),
            React.createElement(Box, null,
                React.createElement(ViewTable, { header: null, emptyDescription: 'No cidr ranges are assigned to this Identity', items: ranges, columnDefinitions: IDENTITIES_VIEW_CIDR_RANGES_COLUMN_DEFINITIONS, columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.IDENTITIES_VIEW_IDENTITY_TYPE_CIDR_RANGES_LIST, sortingColumnIndex: 0, isSortDescending: false })));
    };
    const renderCloudTenants = () => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        const invalidTenants = [];
        const tenants = (_b = (_a = props === null || props === void 0 ? void 0 : props.selectedCloudTenants) === null || _a === void 0 ? void 0 : _a.map((tenant) => {
            var _a, _b;
            if (!tenant.isValid) {
                invalidTenants.push(tenant);
            }
            return {
                name: (_a = tenant === null || tenant === void 0 ? void 0 : tenant.tenantName) !== null && _a !== void 0 ? _a : '',
                id: tenant.isValid ? tenant === null || tenant === void 0 ? void 0 : tenant.value : NotFoundTextSpan(tenant === null || tenant === void 0 ? void 0 : tenant.value),
                path: (_b = tenant === null || tenant === void 0 ? void 0 : tenant.path) !== null && _b !== void 0 ? _b : ''
            };
        })) !== null && _b !== void 0 ? _b : [];
        let cloudTenantStructureLink = NotFoundTextSpan();
        if ((_d = (_c = props === null || props === void 0 ? void 0 : props.cloudTenantDetails) === null || _c === void 0 ? void 0 : _c.CloudTenants_by_pk) === null || _d === void 0 ? void 0 : _d.id) {
            cloudTenantStructureLink = React.createElement("span", { className: 'kivera-external-link' },
                React.createElement(ExternalLink, { to: `${PATHS.SETTINGS}/${SETTINGS_TABS.CLOUD_TENANTS}/${(_f = (_e = props === null || props === void 0 ? void 0 : props.cloudTenantDetails) === null || _e === void 0 ? void 0 : _e.CloudTenants_by_pk) === null || _f === void 0 ? void 0 : _f.id}`, label: (_h = (_g = props === null || props === void 0 ? void 0 : props.cloudTenantDetails) === null || _g === void 0 ? void 0 : _g.CloudTenants_by_pk) === null || _h === void 0 ? void 0 : _h.name }));
        }
        return React.createElement(SpaceBetween, { size: "l" },
            React.createElement(ColumnLayout, { columns: 2, variant: "text-grid" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Identifier"),
                    React.createElement(Box, null,
                        React.createElement(Badge, { color: 'grey' }, indentifierLabelObject[(_j = props === null || props === void 0 ? void 0 : props.identity) === null || _j === void 0 ? void 0 : _j.type]))),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Cloud Tenant Structure"),
                    (props === null || props === void 0 ? void 0 : props.cloudTenantDetails) ?
                        React.createElement(Box, null,
                            React.createElement("span", { style: { marginRight: 6 } },
                                React.createElement(Icon, { size: "medium", url: getLogoAsUrl((_m = (_l = (_k = props === null || props === void 0 ? void 0 : props.cloudTenantDetails) === null || _k === void 0 ? void 0 : _k.CloudTenants_by_pk) === null || _l === void 0 ? void 0 : _l.Provider) === null || _m === void 0 ? void 0 : _m.name), alt: ((_q = (_p = (_o = props === null || props === void 0 ? void 0 : props.cloudTenantDetails) === null || _o === void 0 ? void 0 : _o.CloudTenants_by_pk) === null || _p === void 0 ? void 0 : _p.Provider) === null || _q === void 0 ? void 0 : _q.name) || '' })),
                            cloudTenantStructureLink)
                        : '-')),
            React.createElement(ViewTable, { header: React.createElement(Header, { variant: "h3" }, "Tenant IDs"), emptyDescription: 'No Cloud Tenant IDs are assigned to this Identity', items: tenants, columnDefinitions: IDENTITIES_VIEW_CLOUD_TENANTS_COLUMN_DEFINITIONS, columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.IDENTITIES_VIEW_IDENTITY_TYPE_CLOUD_TENANTS_LIST, sortingColumnIndex: 0, isSortDescending: false }),
            invalidTenants.length > 0 ?
                React.createElement(Alert, { type: 'error' }, "One or more errors were encountered while referencing this cloud tenant structure. This could be because the structure has been deleted or modified, and may cause this identity to not function as intended. Please edit the identity or the cloud tenant structure to correct this.")
                : null);
    };
    const renderUserIdentities = () => {
        var _a, _b, _c, _d, _e, _f;
        let userIdentityAttributes = [];
        if (((_a = props === null || props === void 0 ? void 0 : props.userIdentityAttributes) === null || _a === void 0 ? void 0 : _a.mapping) && _.isObject((_b = props === null || props === void 0 ? void 0 : props.userIdentityAttributes) === null || _b === void 0 ? void 0 : _b.mapping)) {
            userIdentityAttributes = (_e = (_d = Object.keys((_c = props === null || props === void 0 ? void 0 : props.userIdentityAttributes) === null || _c === void 0 ? void 0 : _c.mapping)) === null || _d === void 0 ? void 0 : _d.map((attribute) => {
                var _a, _b, _c, _d, _e;
                let val = '';
                if (Array.isArray((_b = (_a = props === null || props === void 0 ? void 0 : props.userIdentityAttributes) === null || _a === void 0 ? void 0 : _a.mapping) === null || _b === void 0 ? void 0 : _b[attribute]) && ((_e = (_d = (_c = props === null || props === void 0 ? void 0 : props.userIdentityAttributes) === null || _c === void 0 ? void 0 : _c.mapping) === null || _d === void 0 ? void 0 : _d[attribute]) === null || _e === void 0 ? void 0 : _e.length) > 0) {
                    val = props.userIdentityAttributes.mapping[attribute][0];
                }
                return {
                    attribute: attribute !== null && attribute !== void 0 ? attribute : '',
                    value: val
                };
            })) !== null && _e !== void 0 ? _e : [];
        }
        return React.createElement(SpaceBetween, { size: "l" },
            React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Identifier"),
                    React.createElement(Box, null,
                        React.createElement(Badge, { color: 'grey' }, indentifierLabelObject[(_f = props === null || props === void 0 ? void 0 : props.identity) === null || _f === void 0 ? void 0 : _f.type])))),
            React.createElement(ViewTable, { header: React.createElement(Header, { variant: "h3" }, "User Identity Attributes"), emptyDescription: 'No User Identity Attributes are assigned to this Identity', items: userIdentityAttributes, columnDefinitions: IDENTITIES_VIEW_USER_IDENTITIES_COLUMN_DEFINITIONS, columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.IDENTITIES_VIEW_IDENTITY_TYPE_USER_IDENTITY_ATTRIBUTES_LIST, sortingColumnIndex: 0, isSortDescending: false }));
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Identity Settings") },
            ((_a = props === null || props === void 0 ? void 0 : props.identity) === null || _a === void 0 ? void 0 : _a.type) === IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH ? renderPassword() : null,
            ((_b = props === null || props === void 0 ? void 0 : props.identity) === null || _b === void 0 ? void 0 : _b.type) === IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE ? renderCidrRanges() : null,
            ((_c = props === null || props === void 0 ? void 0 : props.identity) === null || _c === void 0 ? void 0 : _c.type) === IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT ? renderCloudTenants() : null,
            ((_d = props === null || props === void 0 ? void 0 : props.identity) === null || _d === void 0 ? void 0 : _d.type) === IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY ? renderUserIdentities() : null));
};
const IdentityProfiles = (props) => {
    var _a, _b;
    const navigate = useNavigate();
    const location = useLocation();
    const attachedProfiles = ((_a = props === null || props === void 0 ? void 0 : props.identity) === null || _a === void 0 ? void 0 : _a.identityProfiles) || [];
    const totalItemslength = ((_b = props === null || props === void 0 ? void 0 : props.identity) === null || _b === void 0 ? void 0 : _b.profilesAggregateCount) || attachedProfiles.length;
    const counter = attachedProfiles.length > 0 ? `(${attachedProfiles.length}/${totalItemslength})` : `(0/${totalItemslength})`;
    const buttonText = attachedProfiles.length > 0 ? 'Manage Profiles' : 'Attach Profile';
    return React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
                React.createElement(Box, null,
                    React.createElement(InmemoryDetailsTable, { header: React.createElement(TableHeader, { title: "Profiles", counter: counter, selectedItems: attachedProfiles, totalItems: attachedProfiles, description: 'Profiles attached to this identity', actionButtons: React.createElement(Button, { onClick: () => { var _a; return navigate(`${PATHS.IDENTITIES}/${(_a = props === null || props === void 0 ? void 0 : props.identity) === null || _a === void 0 ? void 0 : _a.id}/edit#profiles`); } }, buttonText) }), columnDefinitions: IdentitiesProfilesColumnDefinitions(location === null || location === void 0 ? void 0 : location.pathname, true), items: attachedProfiles, filteringAriaLabel: 'Filter Profiles', filteringPlaceholder: 'Filter Profiles', columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.IDENTITIES_VIEW_PROFILES_LIST, preferencesStorageKey: PREFERENCES_STORAGE_KEY.IDENTITIES_VIEW_PROFILES_LIST, defaultPreferences: IdentitiesProfilesDetailsTablePreferences, visibleContentOptions: IdentitiesProfilesDetailsTableVisibleContentOptions, pageSizeOptions: IdentitiesProfilesDetailsTablePageSizeOptions, ariaLabels: {}, emptyText: "No profiles are attached to this identity", propertyFilterI18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Profiles' }), propertyFilterFilteringProperties: IdentitiesProfilesDetailsFilteringProperties, propertyFilterFilteringOptions: [], propertyFilterStorageName: TABLE_FILTERS_SESSION_STORAGE.IDENTITIES_VIEW_PROFILES_LIST, propertySortingStorageName: TABLE_SORTING_SESSION_STORAGE.IDENTITIES_VIEW_PROFILES_LIST })))));
};
const IdentityTags = (props) => {
    var _a, _b, _c;
    const tags = (_b = (_a = props === null || props === void 0 ? void 0 : props.identity) === null || _a === void 0 ? void 0 : _a.tags) !== null && _b !== void 0 ? _b : [];
    const currentId = (_c = props === null || props === void 0 ? void 0 : props.identity) === null || _c === void 0 ? void 0 : _c.id;
    return React.createElement(ShowTags, { tags: tags, id: currentId, resource: "identity", basePath: PATHS.IDENTITIES });
};
const IdentitiesView = (props) => {
    var _a, _b, _c;
    const { setErrorStatus } = useErrorBoundaryContext();
    const params = useParams();
    const navigate = useNavigate();
    const [identityDetails, setIdentityDetails] = useState(undefined);
    const [cloudTenantDetails, setCloudTenantDetails] = useState(undefined);
    const [selectedCloudTenants, setSelectedCloudTenants] = useState([]);
    const [userIdentityAttributes, setUserIdentityAttributes] = useState(undefined);
    const [formattedIdentity, setFormattedIdentity] = useState(undefined);
    const [items, setItems] = useState([]);
    const [paramIdentityId, setParamIdentityId] = useState(params === null || params === void 0 ? void 0 : params.identityID);
    const [loading, setLoading] = useState(true);
    const { identities, tenants } = useIdentitiesHandlers();
    const identitiesHandler = identities;
    const cloudTenantsHandler = tenants;
    const isMounted = useIsMounted();
    const { pushNotifications, dismissNotification } = useNotifications();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const onDeleteInit = () => setShowDeleteModal(true);
    const onDeleteDiscard = () => setShowDeleteModal(false);
    const makeSuccessNotification = (message) => {
        const msg = 'Identity deleted Successfully';
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to delete. Please try again later.';
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const onDeleteConfirm = () => {
        var _a;
        const toBeDeleted = parseInt(paramIdentityId, 10);
        (_a = identitiesHandler.deleteBulk([toBeDeleted])) === null || _a === void 0 ? void 0 : _a.then((result) => {
            // if success, unselect the items
            if (result === null || result === void 0 ? void 0 : result.success) {
                pushNotifications(makeSuccessNotification());
                navigate(PATHS.IDENTITIES);
            }
            else { // else show error notification
                pushNotifications(makeErrorNotification());
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            if (isMounted.current) {
                // drop the modal and show success or error
                setShowDeleteModal(false);
            }
        });
    };
    const getCloudTenantsById = (tenanId, tenantsSelected) => {
        cloudTenantsHandler === null || cloudTenantsHandler === void 0 ? void 0 : cloudTenantsHandler.get({ id: tenanId }).then(details => {
            var _a, _b, _c, _d, _e;
            if ((_a = details === null || details === void 0 ? void 0 : details.CloudTenants_by_pk) === null || _a === void 0 ? void 0 : _a.id) {
                setCloudTenantDetails(details);
                const cloudTenantStructure = (_b = details === null || details === void 0 ? void 0 : details.CloudTenants_by_pk) === null || _b === void 0 ? void 0 : _b.tenant_structure;
                if (cloudTenantStructure) {
                    const options = cloudTenantsHandler === null || cloudTenantsHandler === void 0 ? void 0 : cloudTenantsHandler.getCloudTenantOptions((_c = details === null || details === void 0 ? void 0 : details.CloudTenants_by_pk) === null || _c === void 0 ? void 0 : _c.tenant_structure, tenantsSelected);
                    setSelectedCloudTenants(options);
                }
            }
            //  cloud tenant details deleted in db
            else if (tenanId !== ((_d = details === null || details === void 0 ? void 0 : details.CloudTenants_by_pk) === null || _d === void 0 ? void 0 : _d.id)) {
                const notFoundTenants = (_e = tenantsSelected === null || tenantsSelected === void 0 ? void 0 : tenantsSelected.map(tenant => {
                    return {
                        value: tenant,
                        isTenant: false,
                        path: '',
                        labelSearchable: '[Not Found]',
                        tenantName: '',
                        isValid: false
                    };
                })) !== null && _e !== void 0 ? _e : [];
                setSelectedCloudTenants(notFoundTenants);
            }
        }).catch(console.error);
    };
    const getIdenttiesEntity = (id) => {
        identitiesHandler.getEntity(id).then((identity) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            if (isMounted.current) {
                //  we only support APPLICATION identity type at the moment, rest of them send to oops
                if (identity && ((_b = (_a = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id)) {
                    const formattedItems = identitiesHandler === null || identitiesHandler === void 0 ? void 0 : identitiesHandler.getFormattedEntity(identity);
                    setFormattedIdentity(formattedItems);
                    setItems([formattedItems]);
                    setIdentityDetails(identity);
                    // though it says identity_type, in reality it is authentication type
                    if (((_d = (_c = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.identity_type) === IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT) {
                        const tenantDetails = identitiesHandler === null || identitiesHandler === void 0 ? void 0 : identitiesHandler.getCloudTenantDetailsFromConfig((_f = (_e = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.config);
                        if (tenantDetails === null || tenantDetails === void 0 ? void 0 : tenantDetails.id) {
                            const { id: tenanId, selected: tenantsSelected = [] } = tenantDetails;
                            getCloudTenantsById(tenanId, tenantsSelected);
                        }
                    }
                    else if (((_h = (_g = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.identity_type) === IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY) {
                        const userIdentityDetails = identitiesHandler === null || identitiesHandler === void 0 ? void 0 : identitiesHandler.getUserIdentitiesDetailsFromConfig((_k = (_j = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.config);
                        setUserIdentityAttributes(userIdentityDetails);
                    }
                }
                else {
                    setErrorStatus(true);
                }
            }
        }).catch(console.error)
            .finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    useEffect(() => {
        if (paramIdentityId && parseInt(paramIdentityId, 10) !== 0) {
            const id = parseInt(paramIdentityId, 10);
            getIdenttiesEntity(id);
        }
        else {
            setLoading(false);
            setErrorStatus(true);
        }
    }, []);
    useEffect(() => {
        if (isMounted.current) {
            setParamIdentityId(params === null || params === void 0 ? void 0 : params.identityID);
        }
    }, [params === null || params === void 0 ? void 0 : params.identityID]);
    if ((identityDetails === undefined) || loading) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return (React.createElement(ContentWrapper, { testid: "identity-view-page" },
        React.createElement(ContentLayout, { header: React.createElement(Header, { variant: "h1", actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { "data-testid": "click-to-delete-this-identity", onClick: onDeleteInit }, "Delete"),
                    React.createElement(Button, { variant: "primary", "ata-testid": "click-to-edit-this-identity", onClick: () => {
                            navigate(PATHS.IDENTITIES + '/' + paramIdentityId + '/edit');
                        } }, "Edit")) }, (_c = (_b = (_a = identityDetails === null || identityDetails === void 0 ? void 0 : identityDetails.Identities) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '') },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(IdentityDetails, { identity: formattedIdentity, identitiesHandler: identitiesHandler }),
                React.createElement(IdentitySettings, { identity: formattedIdentity, identitiesHandler: identitiesHandler, cloudTenantDetails: cloudTenantDetails, selectedCloudTenants: selectedCloudTenants, userIdentityAttributes: userIdentityAttributes }),
                React.createElement(IdentityProfiles, { identity: formattedIdentity, identitiesHandler: identitiesHandler, identityID: paramIdentityId, proxyID: params === null || params === void 0 ? void 0 : params.proxyID }),
                React.createElement(IdentityTags, { identity: formattedIdentity, identitiesHandler: identitiesHandler }),
                React.createElement(DeleteModal, { visible: showDeleteModal, onDiscard: onDeleteDiscard, onDelete: onDeleteConfirm, items: items, deleteModalStrings: IdentitiesDeleteStrings, itemDeleteShowProp: IdentityDeleteShowProp })))));
};
export default IdentitiesView;
