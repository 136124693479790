import React, { useEffect, useState } from "react";
import { useColumnWidths } from "@/presentation/components/commons/use-column-widths";
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from "@/presentation/pages/constants-and-types";
import { useLocalStorage } from "@/presentation/components/commons/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableHeader, TableNoMatchState } from "@/presentation/components/commons/common-components";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import { Alert, Box, Button, CollectionPreferences, ColumnLayout, Container, ExpandableSection, Pagination, PropertyFilter, SpaceBetween, Table } from '@cloudscape-design/components-themed/components';
import { makeNotification, makePropertyFilterI18nStrings, PagesRequestErrorHandler } from "@/presentation/pages";
import Skeleton from "react-loading-skeleton";
import ContentWrapper from "@/presentation/components/content-wrapper";
import { PATH_PROXIES, PATH_SETTINGS, TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import { getFilterCounterText } from "@/presentation/components/commons/tableCounterStrings";
import { paginationLabels } from "@/presentation/components/commons/labels";
import DeleteModal from "@/presentation/components/modal/delete-modal";
import TableContainer from "@/presentation/components/table/table-container";
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from "@/presentation/storage/misc-storage";
import { useIsMounted } from '@/presentation/hooks';
import { DomainACLsDeleteShowProp, DomainACLsDeleteStrings, DomainACLsListColumnDefinitions, DomainACLsListFilteringProperties, DomainACLsListTablePageSizeOptions, DomainACLsListTablePreferences, DomainACLsListTableVisibleContentOptions } from '@/presentation/pages/settings/domain-acls/settings-domain-acls-config';
import InformationModal from '@/presentation/components/modal/information-modal';
import { getRandomUUID } from '@/lib/misc/utils';
import ExternalLink from '@/presentation/components/links/external-link';
import { manageCreatedUpdatedList } from "../../common-config";
const SettingsDomainACLsEmptyState = () => {
    return React.createElement(Box, { margin: { vertical: 'xs' }, textAlign: "center", color: "inherit" },
        React.createElement(SpaceBetween, { size: "xxs" },
            React.createElement(Box, null,
                React.createElement("b", null, "No domain acls have been defined."))));
};
const SettingsDomainACLs = (props) => {
    var _a;
    const [loading, setLoading] = useState(true);
    const [domainACLs, setDomainACLs] = useState([]);
    const [selectedItems, setSelectedItems] = useState(props.selectedItems);
    const [columnDefinitions] = useColumnWidths(COLUMN_WIDTH_STORAGE_KEY.SETTINGS_DOMAIN_ACLS, DomainACLsListColumnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.SETTINGS_DOMAIN_ACLS, DomainACLsListTablePreferences);
    const getSessionPropertyFilter = GetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_DOMAIN_ACLS_LIST);
    const getSessionPropertySorting = GetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.SETTINGS_DOMAIN_ACLS_LIST);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : DomainACLsListColumnDefinitions === null || DomainACLsListColumnDefinitions === void 0 ? void 0 : DomainACLsListColumnDefinitions[0],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(domainACLs, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: DomainACLsListFilteringProperties,
            empty: React.createElement(SettingsDomainACLsEmptyState, null),
            noMatch: (React.createElement(TableNoMatchState, { onClearFilter: () => {
                    actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                } }))
        },
        sorting: { defaultState: defaultSortingState },
        pagination: { pageSize: preferences.pageSize },
        selection: {}
    });
    const [renderCount, setRenderCount] = useState(1);
    const navigate = useNavigate();
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
    const { pushNotifications, dismissNotification } = useNotifications();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const onDeleteInit = () => setShowDeleteModal(true);
    const onDeleteDiscard = () => setShowDeleteModal(false);
    const [showInformationModal, setShowInformationModal] = useState(false);
    const onInformationDiscard = () => setShowInformationModal(false);
    const [associatedProxies, setAssociatedProxies] = useState([]);
    const [userFilteringOptions, setUserFilteringOptions] = useState([]);
    const isMounted = useIsMounted();
    const { domainACL } = useSettingsHandlers();
    const domainACLHandler = domainACL;
    const makeSuccessNotification = () => {
        const msg = 'Domain ACL Deleted Successfully';
        return makeNotification("success", msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to delete. Please try again later.';
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const refreshList = () => {
        if (isMounted.current) {
            setLoading(true);
            setSelectedItems([]);
        }
        domainACLHandler.list().then((result) => {
            const formattedDomainACLs = domainACLHandler.getFormattedDomainACLs(result);
            if (isMounted.current) {
                const urlID = props === null || props === void 0 ? void 0 : props.urlDomainAclID;
                if (urlID) {
                    const filteredID = formattedDomainACLs.filter(item => Number(urlID) === item.id) || [];
                    if (filteredID.length > 0) {
                        props.onSelectionChange({ detail: { selectedItems: filteredID } });
                        setSelectedItems(filteredID);
                        setIsDeleteDisabled(false);
                    }
                }
                setDomainACLs(formattedDomainACLs);
                const userFilteredOptionsList = manageCreatedUpdatedList(formattedDomainACLs);
                setUserFilteringOptions(userFilteredOptionsList);
            }
        }).catch(console.error).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    useEffect(() => {
        refreshList();
    }, []);
    const onDeleteConfirm = () => {
        var _a;
        domainACLHandler.get({
            id: (_a = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _a === void 0 ? void 0 : _a.id
        }).then((acl) => {
            var _a, _b, _c, _d;
            const proxies = (_c = (_b = (_a = acl === null || acl === void 0 ? void 0 : acl.DomainAcls_by_pk) === null || _a === void 0 ? void 0 : _a.ProxyDomainAcls) === null || _b === void 0 ? void 0 : _b.map(proxy => {
                var _a, _b;
                return {
                    id: (_a = proxy === null || proxy === void 0 ? void 0 : proxy.Proxy) === null || _a === void 0 ? void 0 : _a.id,
                    name: (_b = proxy === null || proxy === void 0 ? void 0 : proxy.Proxy) === null || _b === void 0 ? void 0 : _b.name,
                };
            })) !== null && _c !== void 0 ? _c : [];
            setAssociatedProxies(proxies);
            if ((proxies === null || proxies === void 0 ? void 0 : proxies.length) > 0 && isMounted.current) {
                setShowDeleteModal(false);
                setShowInformationModal(true);
                setIsDeleteDisabled(false);
            }
            else {
                domainACLHandler.delete({
                    id: (_d = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _d === void 0 ? void 0 : _d.id
                }).then((result) => {
                    var _a, _b;
                    // if success, unselect the items
                    if (((_a = result === null || result === void 0 ? void 0 : result.delete_DomainAcls_by_pk) === null || _a === void 0 ? void 0 : _a.id) === ((_b = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _b === void 0 ? void 0 : _b.id)) {
                        pushNotifications(makeSuccessNotification());
                        refreshList();
                        props.onDelete();
                        if (isMounted.current) {
                            setIsDeleteDisabled(true);
                        }
                    }
                    else { // else show error notification
                        const customMsg = "Unable to delete. Please try again later.";
                        pushNotifications(makeErrorNotification(customMsg));
                    }
                }).catch(({ response }) => {
                    handleErrorResponse(response);
                }).finally(() => {
                    if (isMounted.current) {
                        setShowDeleteModal(false);
                    }
                });
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        });
    };
    if (loading) {
        return React.createElement(Skeleton, { count: 12 });
    }
    return React.createElement(ContentWrapper, null,
        React.createElement(Container, null,
            React.createElement(TableContainer, { key: renderCount },
                React.createElement(Table, Object.assign({}, collectionProps, { key: props.splitKeyValue, resizableColumns: true, loading: loading, loadingText: 'Loading Domain ACLs...', columnDefinitions: columnDefinitions, visibleColumns: preferences.visibleContent, items: items, selectionType: "single", variant: "embedded", wrapLines: preferences.wrapLines, selectedItems: selectedItems, onSelectionChange: (event) => {
                        props.onSelectionChange(event);
                        setSelectedItems(event.detail.selectedItems);
                        setIsDeleteDisabled(false);
                    }, onSortingChange: ({ detail }) => {
                        SetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.SETTINGS_DOMAIN_ACLS_LIST, detail);
                        actions.setSorting(detail);
                    }, header: React.createElement(TableHeader, { title: "Domain ACLs", description: "Domain ACLs are used when non-provider traffic is enabled through the proxy, and provide fine-grained control over which non-provider domains are allowed", selectedItems: selectedItems, totalItems: domainACLs, singleSelect: true, actionButtons: React.createElement(SpaceBetween, { size: "xs", direction: "horizontal" },
                            React.createElement(Button, { variant: "normal", disabled: isDeleteDisabled, onClick: onDeleteInit }, "Delete"),
                            React.createElement(Button, { variant: "normal", disabled: isDeleteDisabled, onClick: () => { var _a; return navigate(PATH_SETTINGS.DOMAIN_ACLS + '/' + ((_a = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _a === void 0 ? void 0 : _a.id) + '/edit'); } }, "Edit"),
                            React.createElement(Button, { variant: "primary", onClick: () => navigate(PATH_SETTINGS.DOMAIN_ACLS_CREATE) }, "Create Domain ACL")) }), filter: React.createElement(SpaceBetween, { size: 'l', direction: 'horizontal' },
                        React.createElement("div", { className: 'list-property-filter' },
                            React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Domain ACLs' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: userFilteringOptions, filteringProperties: DomainACLsListFilteringProperties, onChange: ({ detail }) => {
                                    SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_DOMAIN_ACLS_LIST, detail);
                                    actions.setPropertyFiltering(detail);
                                } })))), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                            setPreferences(detail);
                            setRenderCount(renderCount + 1);
                        }, pageSizePreference: {
                            title: 'Page Size',
                            options: DomainACLsListTablePageSizeOptions
                        }, wrapLinesPreference: {
                            label: 'Wrap Lines',
                            description: 'If checked, lines will be wrapped to display all of the text'
                        }, visibleContentPreference: {
                            title: 'Visible Columns',
                            options: DomainACLsListTableVisibleContentOptions
                        } }) })),
                React.createElement(DeleteModal, { visible: showDeleteModal, onDiscard: onDeleteDiscard, onDelete: onDeleteConfirm, items: selectedItems, deleteModalStrings: DomainACLsDeleteStrings, itemDeleteShowProp: DomainACLsDeleteShowProp }),
                React.createElement(InformationModal, { visible: showInformationModal, onDiscard: onInformationDiscard, discardButtonText: 'Close', header: 'Domain ACL In Use', info: React.createElement(SpaceBetween, { size: "m" },
                        React.createElement(Alert, { type: 'warning' },
                            "Domain ACL ",
                            React.createElement("b", null, (_a = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _a === void 0 ? void 0 : _a.name),
                            " cannot be deleted because it is being used by ",
                            (associatedProxies === null || associatedProxies === void 0 ? void 0 : associatedProxies.length) > 1 ? (associatedProxies === null || associatedProxies === void 0 ? void 0 : associatedProxies.length) + ' proxies' : '1 proxy',
                            ". Please detach the Domain ACL from the following ",
                            (associatedProxies === null || associatedProxies === void 0 ? void 0 : associatedProxies.length) > 1 ? 'proxies' : 'proxy',
                            " before deleting it."),
                        React.createElement(ColumnLayout, { columns: 1 }, React.createElement(ExpandableSection, { headerText: "Proxies using this Domain ACL", defaultExpanded: true }, React.createElement("ul", null, associatedProxies === null || associatedProxies === void 0 ? void 0 : associatedProxies.map(proxy => {
                            const link = PATH_PROXIES.VIEW + '/' + (proxy === null || proxy === void 0 ? void 0 : proxy.id);
                            return React.createElement("li", { key: getRandomUUID() },
                                React.createElement("div", { className: 'kivera-external-link' },
                                    React.createElement(ExternalLink, { to: link, label: proxy === null || proxy === void 0 ? void 0 : proxy.name })));
                        }))))) }))));
};
export default SettingsDomainACLs;
