import React from "react";
import { useAuth0 } from "@/lib/auth0";
import OverlayBouncyLoader from "@/presentation/components/loader/overlay-bouncy-loader";
import Support from "@/presentation/components/support/support";
import AppRouter from "./app-router";
import Documentation from "@/presentation/components/docs/docs";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorBoundary from '@/presentation/pages/errors/error-boundary';
import { getRandomUUID } from "@/lib/misc/utils";
import { NotificationsHandler } from "@/presentation/handlers";
import { NotificationsProvider } from '@/presentation/providers/notifications-provider';
const App = (props) => {
    const notificationsHandler = new NotificationsHandler();
    const { user } = useAuth0();
    if (!user) {
        return React.createElement(OverlayBouncyLoader, null);
    }
    const windowLocationSearch = decodeURIComponent(window.location.search);
    const searchParams = new URLSearchParams(windowLocationSearch);
    const hasRedirectParams = searchParams.has("redirect");
    const getRedirectParams = searchParams.get("redirect");
    const isRedirectExist = hasRedirectParams && getRedirectParams.includes("/docs");
    if (isRedirectExist) {
        const splitRedirectParams = getRedirectParams === null || getRedirectParams === void 0 ? void 0 : getRedirectParams.split("?");
        const docsPath = `https://docs.kivera.io${splitRedirectParams[0]}`;
        return React.createElement(Documentation, { docsPath: docsPath });
    }
    const hasSupportParams = searchParams.has("return_to");
    const getSupportParams = searchParams.get("return_to");
    const windowPathName = window.location.pathname;
    const isSupportExist = windowPathName.includes("/support") &&
        hasSupportParams &&
        !!getSupportParams;
    if (isSupportExist) {
        return React.createElement(Support, { supportLink: getSupportParams });
    }
    const router = createBrowserRouter([
        {
            path: "*",
            element: (React.createElement(ErrorBoundary, { key: getRandomUUID() },
                React.createElement(NotificationsProvider, { handler: notificationsHandler },
                    React.createElement(AppRouter, null)))),
        }
    ]);
    return React.createElement(RouterProvider, { router: router });
};
export default App;
