var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { Box, Button, ButtonDropdown, CollectionPreferences, Container, Form, FormField, Header, Input, Pagination, PropertyFilter, SpaceBetween, Table, Select, Grid } from "@cloudscape-design/components-themed/components";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import { OptionsArrayValidator } from "@/presentation/handlers";
import { useAuth0 } from "@/lib/auth0";
import { TableHeader, TableNoMatchState } from "@/presentation/components/commons/common-components";
import { getFilterCounterText } from "@/presentation/components/commons/tableCounterStrings";
import { paginationLabels } from "@/presentation/components/commons/labels";
import { useColumnWidths } from "@/presentation/components/commons/use-column-widths";
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from "@/presentation/pages/constants-and-types";
import { useLocalStorage } from "@/presentation/components/commons/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { UserAccessListColumnDefinitions, UserAccessListFilteringOptions, UserAccessListFilteringProperties, UserAccessListTablePageSizeOptions, UserAccessListTablePreferences, UserAccessListTableVisibleContentOptions } from "@/presentation/pages/settings/user-access/settings-user-access-config";
import { makeSelectRoles } from "@/presentation/pages/settings/user-access/common";
import { getOrgIDFromToken } from "@/lib/auth0/utils";
import { ROLETYPE } from "@/presentation/pages/settings/settings-types";
import { makeNotification, makePropertyFilterI18nStrings } from "@/presentation/pages";
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import DeleteModal from "@/presentation/components/modal/delete-modal";
import { UserDeleteShowProp, UserDeleteStrings } from "../settings-types";
import TableContainer from "@/presentation/components/table/table-container";
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from "@/presentation/storage/misc-storage";
import { TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from "@/presentation/common-utils/constants";
import { useIsMounted } from '@/presentation/hooks';
import OverlayBouncyLoader from "@/presentation/components/loader/overlay-bouncy-loader";
import { fetchUniquePropertyFilters } from "@/presentation/common-utils/common-utils";
const UserAccessEmptyState = () => {
    return React.createElement(Box, { margin: { vertical: 'xs' }, textAlign: "center", color: "inherit" },
        React.createElement(SpaceBetween, { size: "xxs" },
            React.createElement(Box, null,
                React.createElement("b", null, "No users found."))));
};
const SettingsUserAccess = (props) => {
    var _a;
    const { user, rawToken } = useAuth0();
    const orgID = getOrgIDFromToken(rawToken);
    const [createButtonLoading, setCreateButtonLoading] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const { pushNotifications, dismissNotification } = useNotifications();
    const { settings } = useSettingsHandlers();
    const settingsHandler = settings;
    const [emails, setEmails] = useState('');
    const [emailOptions, setEmailOptions] = useState([]);
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [allowedDomains, setAllowedDomains] = useState([]);
    const emailAddressesValidator = new OptionsArrayValidator();
    const [selectedItems, setSelectedItems] = useState([]);
    const [actionsDisabled, setActionsDisabled] = useState(true);
    const allRoles = makeSelectRoles((props === null || props === void 0 ? void 0 : props.roles) || []);
    const defaultValue = allRoles.filter(item => { var _a; return item.label === ((_a = ROLETYPE === null || ROLETYPE === void 0 ? void 0 : ROLETYPE.USER) === null || _a === void 0 ? void 0 : _a.toUpperCase()); })[0] || null;
    // Current default value is set to "KIVERA-USER"
    //Pass the defualt value as null if you dont want any default selection
    const [selectedRole, setSelectedRole] = useState(defaultValue);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const onDeleteInit = () => setShowDeleteModal(true);
    const onDeleteDiscard = () => setShowDeleteModal(false);
    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useIsMounted();
    const userAccessColumnDefinitions = UserAccessListColumnDefinitions(props.roles);
    const [columnDefinitions] = useColumnWidths(COLUMN_WIDTH_STORAGE_KEY.USER_ACCESS_LIST, userAccessColumnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.USER_ACCESS_LIST, UserAccessListTablePreferences);
    const getSessionPropertyFilter = GetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_USER_ACCESS_LIST);
    const getSessionPropertySorting = GetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.SETTINGS_USER_ACCESS_LIST);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : userAccessColumnDefinitions === null || userAccessColumnDefinitions === void 0 ? void 0 : userAccessColumnDefinitions[3],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(usersData, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: UserAccessListFilteringProperties,
            empty: React.createElement(UserAccessEmptyState, null),
            noMatch: (React.createElement(TableNoMatchState, { onClearFilter: () => {
                    actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                } })),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: defaultSortingState },
        selection: {}
    });
    const [renderCount, setRenderCount] = useState(1);
    const refreshList = () => {
        if (isMounted.current) {
            setIsLoading(true);
        }
        settingsHandler.listAllUsers({ org_id: orgID }).then((listUsers) => {
            if (isMounted.current) {
                setUsersData(settingsHandler.formatUsers(listUsers));
            }
        })
            .catch(console.error)
            .finally(() => {
            if (isMounted.current) {
                setIsLoading(false);
            }
        });
    };
    const clearError = () => {
        setEmailError(false);
        setEmailErrorMessage('');
    };
    const clearInputs = () => {
        setEmails('');
        setEmailOptions([]);
        setEmailError(false);
        setEmailErrorMessage('');
        setSelectedRole(defaultValue);
        setSubmitDisabled(true);
        setCreateButtonLoading(false);
        refreshList();
    };
    const validateEmails = (emails) => {
        if ((emails === null || emails === void 0 ? void 0 : emails.length) < 1) {
            setSubmitDisabled(true);
            return;
        }
        const spacesRemovedEmails = emails.replace(/\s+/g, '');
        const splitted = spacesRemovedEmails.split(',');
        const options = splitted.map(email => {
            return {
                label: email
            };
        });
        emailAddressesValidator.isValidEmail().isValidEmailAllowedDomain(allowedDomains);
        const validationResult = emailAddressesValidator.validate(options);
        if (validationResult === null || validationResult === void 0 ? void 0 : validationResult.valid) {
            if (isMounted.current) {
                setSubmitDisabled(false);
                clearError();
                setEmailOptions(options);
            }
        }
        else {
            const validationMessage = validationResult === null || validationResult === void 0 ? void 0 : validationResult['message'];
            if (isMounted.current) {
                setEmailOptions([]);
                setEmailError(true);
                setEmailErrorMessage(validationMessage || 'Please enter a list of valid email addresses');
            }
        }
    };
    useEffect(() => {
        settingsHandler.getAllowedDomains({ org_id: orgID })
            .then((org) => {
            var _a, _b, _c;
            if (((_a = org === null || org === void 0 ? void 0 : org.Organizations) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                const allowedDomains = (_c = (_b = org === null || org === void 0 ? void 0 : org.Organizations) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.allowed_domains;
                if (isMounted.current) {
                    setAllowedDomains(allowedDomains);
                }
            }
        })
            .catch(console.error);
    }, []);
    const makeSuccessNotification = (message) => {
        const msg = "Successfull";
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = "Unable to proceed with this action. Please try again later.";
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const onCreateSubmit = (addresses) => {
        setCreateButtonLoading(true);
        const params = addresses.map(email => {
            return {
                email: email.label,
                role_id: Number(selectedRole.value)
            };
        });
        settingsHandler.addUsers(params).then((response) => {
            var _a;
            if (response.error || !(response === null || response === void 0 ? void 0 : response.data) || (response === null || response === void 0 ? void 0 : response.data.length) < 1) {
                const customMsg = "Unable to create. Please try again later.";
                pushNotifications(makeErrorNotification((response === null || response === void 0 ? void 0 : response.msg) || customMsg));
            }
            else {
                let errorUsers = [];
                let successUsers = [];
                (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(user => {
                    if (user === null || user === void 0 ? void 0 : user.error) {
                        errorUsers.push(user.email);
                    }
                    else if (user === null || user === void 0 ? void 0 : user.user_id) {
                        successUsers.push(user.email);
                    }
                });
                // no error occurred
                if (successUsers.length > 0 && errorUsers.length === 0) {
                    const msg = "Users Created Successfully";
                    pushNotifications(makeSuccessNotification(msg));
                    // reset the input box
                    if (isMounted.current) {
                        clearInputs();
                    }
                }
                // all failed
                else if (errorUsers.length > 0 && successUsers.length === 0) {
                    pushNotifications(makeErrorNotification('Unable to Add User(s) ' + errorUsers.join(', ')));
                }
                // nothing happened - this shouldn't happen
                // in future send event to Admin
                else if (errorUsers.length === 0 && successUsers.length === 0) {
                    const msg = "Unable to create. Please try again later.";
                    pushNotifications(makeErrorNotification(msg));
                }
                // partial success
                else if (successUsers.length > 0 && errorUsers.length > 0) {
                    pushNotifications(makeSuccessNotification('User(s) Added ' + successUsers.join(', ')));
                    if (isMounted.current) {
                        clearInputs();
                    }
                }
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            if (isMounted.current) {
                setCreateButtonLoading(false);
            }
        });
    };
    const onSubmitEdit = (item, column, newValue) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        if (isMounted.current) {
            setIsLoading(true);
        }
        const userItem = item;
        const membershipID = (_c = (_b = userItem === null || userItem === void 0 ? void 0 : userItem.roles) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.membershipID;
        const membershipRoles = {
            role_id: Number(newValue === null || newValue === void 0 ? void 0 : newValue.value),
            membership_id: membershipID
        };
        settingsHandler.UpdateMembershipRole(membershipRoles).then((response) => {
            var _a;
            if ((_a = response === null || response === void 0 ? void 0 : response.insert_MembershipRoles_one) === null || _a === void 0 ? void 0 : _a.id) {
                const successMsg = "Roles Updated Successfully";
                pushNotifications(makeSuccessNotification(successMsg));
                refreshList();
            }
            else {
                const errorsg = "Unable to update. Please try again later.";
                pushNotifications(makeErrorNotification(errorsg));
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            if (isMounted.current) {
                setIsLoading(false);
            }
        });
    });
    useEffect(() => {
        refreshList();
        return () => {
            console.log('cleaning up');
        };
    }, [user]);
    const onDeleteConfirm = () => {
        setIsDeleteLoading(true);
        const user = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0];
        const params = {
            user_id: user === null || user === void 0 ? void 0 : user.id,
            org_id: orgID
        };
        settingsHandler.deleteUser(params).then(result => {
            if (result === null || result === void 0 ? void 0 : result.error) {
                const customMsg = "Unable to delete. Please try again later.";
                pushNotifications(makeErrorNotification((result === null || result === void 0 ? void 0 : result.msg) || customMsg));
            }
            else {
                const msg = "User deleted successfully.";
                pushNotifications(makeSuccessNotification(msg));
                if (isMounted.current) {
                    setActionsDisabled(true);
                }
                refreshList();
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            if (isMounted.current) {
                setIsDeleteLoading(false);
                setShowDeleteModal(false);
            }
        });
    };
    const resendVerificationEmail = () => {
        if (isMounted.current) {
            setIsLoading(true);
        }
        const user = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0];
        const params = {
            user_id: user === null || user === void 0 ? void 0 : user.id,
        };
        settingsHandler.resendVerification(params).then(result => {
            if (result === null || result === void 0 ? void 0 : result.error) {
                const customMsg = "Unable to resend invite. Please try again later.";
                pushNotifications(makeErrorNotification((result === null || result === void 0 ? void 0 : result.msg) || customMsg));
            }
            else {
                const msg = "Invitation has been resent successfully.";
                pushNotifications(makeSuccessNotification(msg));
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            if (isMounted.current) {
                setIsLoading(false);
            }
        });
    };
    const performAction = (actionID) => {
        switch (actionID) {
            case 'delete':
                onDeleteInit();
                break;
            case 'resend':
                resendVerificationEmail();
                break;
            default:
        }
    };
    const isInviteDisabled = submitDisabled || emailError || !(selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole.value);
    return React.createElement(React.Fragment, null,
        isLoading && React.createElement(OverlayBouncyLoader, null),
        React.createElement(SpaceBetween, { size: "xl" },
            React.createElement(Container, { header: React.createElement("div", { className: 'container-header-wrapper top' },
                    React.createElement(Header, { variant: "h2" }, "Invite Users")) },
                React.createElement("form", { onSubmit: (event) => event.preventDefault() },
                    React.createElement(Form, null,
                        React.createElement("div", { className: "gridFormColumns" },
                            React.createElement(Grid, { gridDefinition: [
                                    { colspan: { default: 12, s: 7, xs: 5, xxs: 12 } },
                                    { colspan: { default: 12, s: 3, xs: 4, xxs: 12 } },
                                    { colspan: { default: 12, s: 2, xs: 3, xxs: 12 } },
                                ] },
                                React.createElement(FormField, { label: "Enter the email addresses of users to invite", constraintText: "Comma separated list of email addresses", errorText: emailError ? emailErrorMessage : '' },
                                    React.createElement(Input, { placeholder: "user@domain.com", value: emails, onChange: (event) => {
                                            setEmails(event.detail.value);
                                            validateEmails(event.detail.value);
                                        } })),
                                React.createElement("div", { className: "gridFormField" },
                                    React.createElement(Select, { selectedOption: selectedRole, onChange: ({ detail }) => {
                                            setSelectedRole(detail.selectedOption);
                                        }, options: allRoles, placeholder: "Select a role" })),
                                React.createElement("div", { className: "gridFormField" },
                                    React.createElement(Button, { variant: (isInviteDisabled || createButtonLoading) ? 'normal' : 'primary', disabled: isInviteDisabled, loading: createButtonLoading, onClick: () => onCreateSubmit(emailOptions) }, "Invite"))))))),
            React.createElement(Container, null,
                React.createElement(TableContainer, { key: renderCount }, (React.createElement(Table, Object.assign({}, collectionProps, { resizableColumns: true, columnDefinitions: columnDefinitions, visibleColumns: preferences.visibleContent, items: items, ariaLabels: {}, variant: "embedded", wrapLines: preferences.wrapLines, selectionType: "single", selectedItems: selectedItems, onSelectionChange: event => {
                        setSelectedItems(event.detail.selectedItems);
                        setActionsDisabled(false);
                    }, loadingText: "Loading Users...", onSortingChange: ({ detail }) => {
                        SetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.SETTINGS_USER_ACCESS_LIST, detail);
                        actions.setSorting(detail);
                    }, header: React.createElement(TableHeader, { title: 'User Access', selectedItems: selectedItems, totalItems: usersData, singleSelect: true, actionButtons: React.createElement(SpaceBetween, { size: "xs", direction: "horizontal" },
                            React.createElement(ButtonDropdown, { items: [
                                    { text: "Delete", id: 'delete', disabled: false },
                                    { text: "Resend Invite", id: 'resend', disabled: (((_a = selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems[0]) === null || _a === void 0 ? void 0 : _a.verified) === 'ACTIVE') },
                                ], disabled: actionsDisabled, onItemClick: (event) => {
                                    var _a;
                                    performAction((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.id);
                                } }, "Actions")) }), submitEdit: onSubmitEdit, filter: React.createElement(SpaceBetween, { size: "l", direction: "horizontal" },
                        React.createElement("div", { className: 'list-property-filter' },
                            React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Users' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: UserAccessListFilteringOptions, filteringProperties: UserAccessListFilteringProperties, onChange: ({ detail }) => {
                                    const filteredDetail = fetchUniquePropertyFilters(detail);
                                    SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.SETTINGS_USER_ACCESS_LIST, filteredDetail);
                                    actions.setPropertyFiltering(filteredDetail);
                                } })))), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                            setPreferences(detail);
                            setRenderCount(renderCount + 1);
                        }, pageSizePreference: {
                            title: 'Page Size',
                            options: UserAccessListTablePageSizeOptions
                        }, wrapLinesPreference: {
                            label: 'Wrap Lines',
                            description: 'If checked, lines will be wrapped to display all of the text'
                        }, visibleContentPreference: {
                            title: 'Visible Columns',
                            options: UserAccessListTableVisibleContentOptions
                        } }) })))))),
        React.createElement(DeleteModal, { visible: showDeleteModal, onDiscard: onDeleteDiscard, onDelete: onDeleteConfirm, items: selectedItems, deleteModalStrings: UserDeleteStrings, itemDeleteShowProp: UserDeleteShowProp, isDeleteLoading: isDeleteLoading }));
};
export default SettingsUserAccess;
