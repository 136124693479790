import React, { useState } from 'react';
import { Cards, CollectionPreferences, Pagination, PropertyFilter, } from '@cloudscape-design/components-themed/components';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { getFilterCounterText } from '@/presentation/components/commons/tableCounterStrings';
import { TableHeader, TableNoMatchState } from '@/presentation/components/commons/common-components';
import { makePropertyFilterI18nStrings } from "@/presentation/pages";
import { GetPropertyFilters, SetPropertyFilters } from '@/presentation/storage/misc-storage';
import { fetchUniquePropertyFilters } from '@/presentation/common-utils/common-utils';
const InmemoryCards = (props) => {
    const getSessionPropertyFilter = GetPropertyFilters(props.propertyFilterStorageName);
    const [preferences, setPreferences] = useLocalStorage(props.preferencesStorageKey, props.defaultPreferences);
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(props.items, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: props.propertyFilterFilteringProperties,
            empty: props.tableEmptyState,
            noMatch: (React.createElement(TableNoMatchState, { onClearFilter: () => {
                    actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                } }))
        },
        pagination: { pageSize: preferences.pageSize },
        selection: {}
    });
    const [selectedItems, setSelectedItems] = useState([]);
    return React.createElement(React.Fragment, null,
        React.createElement(Cards, Object.assign({}, collectionProps, { stickyHeader: true, cardDefinition: props.columnDefinitions, visibleSections: preferences.visibleContent, loading: props.loading, loadingText: props.loadingText, items: items, selectedItems: selectedItems, onSelectionChange: event => {
                setSelectedItems(event.detail.selectedItems);
                props.selectionChangeCallback(event.detail.selectedItems);
            }, selectionType: "multi", variant: "full-page", cardsPerRow: [{
                    cards: 1
                }, {
                    minWidth: 692,
                    cards: 2
                }, {
                    minWidth: 1024,
                    cards: 3
                }, {
                    minWidth: 1400,
                    cards: 4
                }, {
                    minWidth: 1700,
                    cards: 5
                }, {
                    minWidth: 1920,
                    cards: 6
                }], header: React.createElement(TableHeader, { variant: "awsui-h1-sticky", title: props.resourceName, selectedItems: selectedItems, totalItems: props.items, actionButtons: props.actionButtons }), filter: React.createElement("div", { className: 'list-property-filter' },
                React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: props.filteringPlaceholder }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: props.propertyFilterFilteringOptions, filteringProperties: props.propertyFilterFilteringProperties, onChange: ({ detail }) => {
                        const filteredDetail = fetchUniquePropertyFilters(detail);
                        if (props.propertyFilterStorageName)
                            SetPropertyFilters(props.propertyFilterStorageName, filteredDetail);
                        actions.setPropertyFiltering(filteredDetail);
                    } }))), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: props.paginationLabels, disabled: props.loading })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: props.loading, preferences: preferences, onConfirm: ({ detail }) => setPreferences(detail), pageSizePreference: {
                    title: 'Page Size',
                    options: props.pageSizeOptions
                }, visibleContentPreference: {
                    title: 'Visible Columns',
                    options: props.visibleContentOptions
                } }) })));
};
export default InmemoryCards;
