import React, { useEffect, useState } from 'react';
import { Container, FormField, Header, Button, SpaceBetween, Icon, TextContent, ExpandableSection, Modal, Box } from '@cloudscape-design/components-themed/components';
import { getFieldOnChange } from '@/presentation/components';
import CodeEditor from "@/presentation/components/code-editor/code-editor";
import { Tooltip } from 'react-tooltip';
import ExpandIconSVGIconUrl from '@/presentation/assets/img/icons/expand_icon.svg';
const RulesPolicy = (props) => {
    var _a, _b, _c;
    const [configValue, setConfigValue] = useState(props.info.policyConfig.policy);
    const [constraintText, setConstraintText] = useState(null);
    const [errorText, setErrorText] = useState(null);
    const [editorHeight, setEditorHeight] = useState(window.innerHeight - 250);
    const [isViewContextVisible, setIsViewContextVisible] = useState(false);
    const tooltipRef = React.useRef(null);
    const refEditor = React.useRef(null);
    const policyOnChange = getFieldOnChange('code', 'policy', props.onChange);
    const ref = React.useRef(null);
    const errorRef = React.useRef(null);
    const makeFullConfig = (policy) => {
        return props.globalPolicyFunc + '\r\n' + props.orgPolicyFunc + '\r\n';
    };
    const [fullConfig, setFullConfig] = useState(makeFullConfig(props.info.policyConfig.policy));
    const hashValue = (_a = window.location.hash) === null || _a === void 0 ? void 0 : _a.substring(1);
    const [showPolicyModal, setShowPolicyModal] = useState(hashValue === 'open-policy-editor');
    const handleEditorHeightSettings = (viewContextStatus) => {
        var _a;
        const currentHeight = ((_a = document.getElementById("editorCustomErrorContent")) === null || _a === void 0 ? void 0 : _a.offsetHeight) || 0;
        // Get window height
        // 250px is the approximate value in pixels to remove height taken by margins, paddings and footer buttons
        const windowHeight = window.innerHeight - 250;
        // Update the editor height by removing the space occupied by error information - Max height is 100px
        const updateEditorHeight = currentHeight < 100 ? windowHeight - currentHeight : windowHeight - 100;
        const getHalfTheWindowHeight = (windowHeight / 2);
        // Set a new editor height
        // Check the updated editor height is greater than the half of the window height because we want the editor to be atleast half of the visible window
        // 300px is the height of the view context container + 15px was added due to spacing between the two containers
        const newUpdatedEditorHeight = viewContextStatus ? updateEditorHeight - 315 : updateEditorHeight;
        const newEditorHeight = newUpdatedEditorHeight > getHalfTheWindowHeight ? newUpdatedEditorHeight : getHalfTheWindowHeight;
        setEditorHeight(newEditorHeight);
    };
    const onPolicyInit = () => setShowPolicyModal(true);
    const onPolicyDiscard = () => {
        setIsViewContextVisible(false);
        setShowPolicyModal(false);
        handleEditorHeightSettings(null);
    };
    useEffect(() => {
        if (props.policySuccess) {
            const message = (React.createElement(TextContent, null,
                React.createElement("p", null,
                    " ",
                    React.createElement(Icon, { name: "status-positive", variant: "success" }),
                    " ",
                    React.createElement("strong", null,
                        React.createElement("small", null,
                            " ",
                            props.policyValidationMessage || 'Policy is valid',
                            " ")),
                    " ")));
            setConstraintText(message);
        }
        else {
            setConstraintText(null);
        }
    }, [props.policySuccess]);
    useEffect(() => {
        if (props.policyError) {
            const errMsg = ((props === null || props === void 0 ? void 0 : props.policyValidationMessage) && (props === null || props === void 0 ? void 0 : props.policyValidationMessage) !== '') ? (React.createElement("pre", null, props === null || props === void 0 ? void 0 : props.policyValidationMessage)) : 'Please provide a policy for this rule';
            setErrorText(errMsg);
        }
        else {
            setErrorText(null);
        }
    }, [props.policyError, props.policyValidationMessage]);
    useEffect(() => {
        if ((constraintText || errorText) && errorRef) {
            handleEditorHeightSettings(isViewContextVisible);
        }
    }, [errorRef === null || errorRef === void 0 ? void 0 : errorRef.current, errorText, constraintText, showPolicyModal]);
    const handleCodeChange = (regoCode) => {
        setConfigValue(regoCode);
        setFullConfig(makeFullConfig(regoCode));
        policyOnChange(regoCode);
    };
    const handleExpandSectionChange = ({ expanded }) => {
        handleEditorHeightSettings(expanded);
        setIsViewContextVisible(expanded || false);
    };
    const ruleId = (_c = (_b = props === null || props === void 0 ? void 0 : props.info) === null || _b === void 0 ? void 0 : _b.details) === null || _c === void 0 ? void 0 : _c.id;
    const policyHeader = ruleId ? `Rule:${ruleId} - Edit Policy` : 'Create Rule - Add Policy';
    return (React.createElement("div", { ref: ref, id: "policy" },
        showPolicyModal && (React.createElement("div", { style: { height: `${window.innerHeight - 250}px` }, ref: refEditor, id: "policyEditor" },
            React.createElement(Modal, { size: "max", visible: showPolicyModal, onDismiss: onPolicyDiscard, header: policyHeader, closeAriaLabel: "Close full screen", modalRoot: document.getElementById('mainPage'), "data-classname": "policyEditor", footer: React.createElement(SpaceBetween, { direction: "vertical", size: "l" },
                    React.createElement(Box, { float: "right" },
                        React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                            React.createElement(Button, { variant: "link", onClick: onPolicyDiscard }, "Close"),
                            React.createElement(Button, { variant: "normal", disabled: !configValue, onClick: () => props.validatePolicy() }, "Validate")))) },
                React.createElement(SpaceBetween, { direction: "vertical", size: "xs" },
                    React.createElement(ExpandableSection, { headerText: "View Context", onChange: ({ detail }) => handleExpandSectionChange(detail) },
                        React.createElement(CodeEditor, { code: fullConfig, readOnly: true, height: 300, heightType: 'px', language: 'rego', inverse: true })),
                    React.createElement(CodeEditor, { code: configValue, loading: props.editorLoading, height: editorHeight, heightType: 'px', readOnly: false, language: 'rego', onChange: handleCodeChange }),
                    React.createElement("div", { style: { maxHeight: '100px', overflow: "auto" } },
                        React.createElement(Box, { float: "left", color: "text-status-error" },
                            React.createElement("div", { className: "flex" },
                                errorText && (React.createElement("div", { className: "mr-2" },
                                    React.createElement(Icon, { name: "status-warning", size: "small", variant: "error" }))),
                                React.createElement("div", { ref: errorRef, id: "editorCustomErrorContent" }, errorText || constraintText)))))))),
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2", actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "l" },
                    React.createElement(Button, { variant: "normal", disabled: !configValue, onClick: () => props.validatePolicy() }, "Validate"),
                    React.createElement("button", { className: 'p-1.5 fullScreenEditorImage', onClick: () => {
                            scroll(0, 100);
                            onPolicyInit();
                        }, tabIndex: 0 },
                        React.createElement(Icon, { url: ExpandIconSVGIconUrl, alt: "Full Screen" }),
                        React.createElement(Tooltip, { ref: tooltipRef, className: '!bg-black font-medium', anchorSelect: '.fullScreenEditorImage', place: 'bottom', opacity: 1, globalCloseEvents: { resize: true, escape: true, clickOutsideAnchor: true, scroll: true } }, "Full Screen"))) }, "Policy") },
            React.createElement(SpaceBetween, { direction: "vertical", size: "l" },
                React.createElement(ExpandableSection, { headerText: "View Context" },
                    React.createElement(CodeEditor, { code: fullConfig, readOnly: true, height: 300, heightType: 'px', language: 'rego', inverse: true })),
                React.createElement(FormField, null,
                    React.createElement(CodeEditor, { code: configValue, loading: props.editorLoading, height: 400, heightType: 'px', readOnly: false, language: 'rego', onChange: handleCodeChange }),
                    React.createElement("div", { style: { maxHeight: '100px', overflow: "auto", marginTop: '10px' } },
                        React.createElement(Box, { float: "left", color: "text-status-error" },
                            React.createElement("div", { className: "flex" },
                                errorText && (React.createElement("div", { className: "mr-2" },
                                    React.createElement(Icon, { name: "status-warning", size: "small", variant: "error" }))),
                                React.createElement("div", { ref: errorRef, id: "editorCustomErrorContent" }, errorText || constraintText)))))))));
};
export default RulesPolicy;
