import React from 'react';
import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import gravatarUrl from 'gravatar-url';
import { getRandomUUID } from '@/lib/misc/utils';
import { getLocaleTimestamp } from '@/presentation/common-utils/common-utils';
import { ROLETYPE } from '@/presentation/pages/settings/settings-types';
import { Badge } from '@cloudscape-design/components-themed/components';
import { AUDIT_LOGS_DATERANGE_OPTIONS, AUDIT_LOGS_DATETIME_OPTIONS, AUDIT_LOGS_TABLE_OPTIONS, AUDIT_LOGS_TABLE_SEARCH_QUERY, SortDirection } from '@/presentation/storage';
export const AuditLogsListFilteringProperties = [
    {
        key: 'service',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'API',
        groupValuesLabel: 'API values'
    },
    {
        key: 'username',
        operators: [':', '!:'],
        propertyLabel: 'User Name',
        groupValuesLabel: 'User name values'
    },
    {
        key: 'userrole',
        operators: ['=', '!='],
        propertyLabel: 'Role',
        groupValuesLabel: 'Role values'
    },
    {
        key: 'actionname',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Action Name',
        groupValuesLabel: 'Action Name values'
    },
    {
        key: 'actiontype',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Action Type',
        groupValuesLabel: 'Action Type values'
    },
];
export const AuditLogsListFilteringOptions = [
    {
        propertyKey: 'actiontype',
        value: 'read'
    },
    {
        propertyKey: 'actiontype',
        value: 'write'
    },
    {
        propertyKey: 'userrole',
        value: ROLETYPE.USER
    },
    {
        propertyKey: 'userrole',
        value: ROLETYPE.ADMIN
    },
    {
        propertyKey: 'userrole',
        value: ROLETYPE.READONLY
    }
];
export const AuditLogsListColumnDefinitions = addColumnSortLabels([
    {
        id: 'timestamp',
        sortingField: 'timestamp',
        header: 'Timestamp',
        cell: (item) => {
            return getLocaleTimestamp(item === null || item === void 0 ? void 0 : item.timestamp);
        }
    },
    {
        id: 'service',
        sortingField: 'service',
        header: 'API',
        cell: (item) => {
            return item === null || item === void 0 ? void 0 : item.service;
        }
    },
    {
        id: 'username',
        sortingField: 'username',
        header: 'User Name',
        cell: (item) => {
            var _a;
            return (React.createElement("div", { className: "flex items-center gap-2", key: getRandomUUID() },
                React.createElement("img", { className: "rounded-full", src: gravatarUrl((_a = item === null || item === void 0 ? void 0 : item.useremail) !== null && _a !== void 0 ? _a : '', { size: 28, default: 'retro' }), key: getRandomUUID(), alt: '', title: (item === null || item === void 0 ? void 0 : item.username) || '' }),
                React.createElement("span", { key: getRandomUUID() }, item === null || item === void 0 ? void 0 : item.username)));
        }
    },
    {
        id: 'userrole',
        sortingField: 'userrole',
        header: 'Role',
        cell: (item) => {
            var _a;
            return React.createElement(Badge, { key: getRandomUUID(), color: 'grey' }, (_a = item === null || item === void 0 ? void 0 : item.userrole) === null || _a === void 0 ? void 0 : _a.toUpperCase());
        }
    },
    {
        id: 'actionname',
        sortingField: 'actionname',
        header: 'Action Name',
        cell: (item) => {
            return item === null || item === void 0 ? void 0 : item.actionname;
        }
    },
    {
        id: 'actiontype',
        sortingField: 'actiontype',
        header: 'Action Type',
        cell: (item) => {
            return item === null || item === void 0 ? void 0 : item.actiontype;
        }
    }
]);
export const AuditLogsListTablePreferences = {
    pageSize: 30,
    visibleContent: ['timestamp', 'service', 'username', 'userrole', 'actionname', 'actiontype'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const AuditLogsListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'timestamp', label: 'Timestamp', editable: true },
            { id: 'service', label: 'API', editable: true },
            { id: 'username', label: 'User Name', editable: false },
            { id: 'userrole', label: 'Role', editable: true },
            { id: 'actionname', label: 'Action Name', editable: true },
            { id: 'actiontype', label: 'Action Type', editable: true }
        ]
    }
];
export const AuditLogsListTablePageSizeOptions = [
    { value: 10, label: '10 Logs' },
    { value: 30, label: '30 Logs' },
    { value: 50, label: '50 Logs' }
];
export const AuditLogsInitialTableOptions = {
    tableOptionsStorage: AUDIT_LOGS_TABLE_OPTIONS,
    queryStorage: AUDIT_LOGS_TABLE_SEARCH_QUERY,
    sortDirection: SortDirection.DESC,
    sortField: 'Timestamp',
    dateTimeOptionsStorage: AUDIT_LOGS_DATETIME_OPTIONS,
    dateRangeStorage: AUDIT_LOGS_DATERANGE_OPTIONS
};
export const AuditLogsDefaultDatetimeOptions = {
    startDateTime: 'now-4h',
    endDateTime: 'now'
};
export const AuditLogsDefaultDateRangeFilter = {
    value: {
        amount: 4,
        unit: 'hour',
        type: 'relative',
        key: 'last-4-hours'
    }
};
