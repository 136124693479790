import { AppLayout, BreadcrumbGroup } from '@cloudscape-design/components-themed/components';
import { appLayoutLabels } from '@/presentation/components/commons/labels';
import React from 'react';
import { useNavigate } from 'react-router-dom';
export function KiverascapeLayout(props) {
    const navigate = useNavigate();
    const onBreadCrumbClick = (e) => {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        const event = e === null || e === void 0 ? void 0 : e.detail;
        navigate((event === null || event === void 0 ? void 0 : event.href) || '/');
    };
    let layoutProps = Object.assign({ navigationHide: true, maxContentWidth: Number.MAX_VALUE, toolsHide: true, headerSelector: "#header", ariaLabels: appLayoutLabels }, props);
    if (props === null || props === void 0 ? void 0 : props.breadcrumbMenu) {
        layoutProps = Object.assign({ breadcrumbs: React.createElement(BreadcrumbGroup, { items: props.breadcrumbMenu, onClick: onBreadCrumbClick, expandAriaLabel: "Show path", ariaLabel: "Breadcrumbs" }) }, layoutProps);
    }
    return React.createElement(AppLayout, Object.assign({}, layoutProps));
}
