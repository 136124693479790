var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getOrgIDFromToken } from '@/lib/auth0/utils';
import { getLocaleTimestamp, isOnline } from '@/presentation/common-utils/common-utils';
import { PROXY_MODE_TYPE, PROXY_MODE_TYPE_LABEL } from '@/presentation/pages/proxies/proxies-types';
import { constructTagString } from '@/presentation/pages';
export class ProxiesHandler {
    constructor(remoteHandlers, remotes) {
        this.formattedProxies = [];
        this.getFormData = () => {
            return this.formData;
        };
        this.setFormData = (data) => {
            this.formData = data;
            return this;
        };
        this.makeDefaultMode = (mode) => {
            switch (mode) {
                case PROXY_MODE_TYPE.LEARNING:
                    return {
                        label: PROXY_MODE_TYPE_LABEL.LEARNING,
                        value: PROXY_MODE_TYPE.LEARNING
                    };
                case PROXY_MODE_TYPE.DEFAULT_ALLOW_BLACKLIST:
                    return {
                        label: PROXY_MODE_TYPE_LABEL.DEFAULT_ALLOW_BLACKLIST,
                        value: PROXY_MODE_TYPE.DEFAULT_ALLOW_BLACKLIST
                    };
                case PROXY_MODE_TYPE.DEFAULT_BLOCK_PREVENTATIVE:
                    return {
                        label: PROXY_MODE_TYPE_LABEL.DEFAULT_BLOCK_PREVENTATIVE,
                        value: PROXY_MODE_TYPE.DEFAULT_BLOCK_PREVENTATIVE
                    };
                default:
                    return {
                        label: PROXY_MODE_TYPE_LABEL.DEFAULT_ALLOW_BLACKLIST,
                        value: PROXY_MODE_TYPE.DEFAULT_ALLOW_BLACKLIST
                    };
            }
        };
        this.getLearningModeLabel = (mode) => {
            switch (mode) {
                case PROXY_MODE_TYPE.LEARNING:
                    return PROXY_MODE_TYPE_LABEL.LEARNING;
                case PROXY_MODE_TYPE.DEFAULT_ALLOW_BLACKLIST:
                    return PROXY_MODE_TYPE_LABEL.DEFAULT_ALLOW_BLACKLIST;
                case PROXY_MODE_TYPE.DEFAULT_BLOCK_PREVENTATIVE:
                    return PROXY_MODE_TYPE_LABEL.DEFAULT_BLOCK_PREVENTATIVE;
                default:
                    return PROXY_MODE_TYPE_LABEL.DEFAULT_BLOCK_PREVENTATIVE;
            }
        };
        this.makeProxyDomainACLs = (stepsInfo, proxyID) => {
            var _a, _b;
            let domainACLs = [];
            if ((_a = stepsInfo.details.domainACLs) === null || _a === void 0 ? void 0 : _a.value) {
                domainACLs.push(Object.assign({ domain_acl_id: parseInt((_b = stepsInfo.details.domainACLs) === null || _b === void 0 ? void 0 : _b.value, 10) }, (proxyID ? { proxy_id: proxyID } : {})));
            }
            return domainACLs;
        };
        this.getProxyCreateParams = (orgID, stepsInfo) => {
            var _a, _b, _c, _d;
            return {
                name: stepsInfo.details.name,
                description: stepsInfo.details.description,
                organization_id: orgID,
                debug: stepsInfo.details.logLevel.value === 'DEBUG',
                default_mode: stepsInfo.details.defaultMode.value,
                learning_mode: stepsInfo.details.learningMode,
                allow_noncloud_traffic: stepsInfo.details.nonProviderTraffic,
                domain_acls: this.makeProxyDomainACLs(stepsInfo),
                default_identity_id: ((_a = stepsInfo.identities.defaultIdentity) === null || _a === void 0 ? void 0 : _a.value) ? parseInt((_b = stepsInfo.identities.defaultIdentity) === null || _b === void 0 ? void 0 : _b.value, 10) : null,
                proxy_mode: 'HYBRID',
                tags: (_d = (_c = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.tags) === null || _c === void 0 ? void 0 : _c.editorTags) === null || _d === void 0 ? void 0 : _d.filter(tag => !(tag === null || tag === void 0 ? void 0 : tag.markedForRemoval) && !!tag.key).map(tag => {
                    return {
                        key: tag === null || tag === void 0 ? void 0 : tag.key,
                        value: tag === null || tag === void 0 ? void 0 : tag.value
                    };
                }),
                providers: stepsInfo.details.providers.map(provider => {
                    var _a;
                    return {
                        provider_id: (_a = parseInt(provider.value)) !== null && _a !== void 0 ? _a : 0,
                        enabled: true
                    };
                }),
                identities: stepsInfo.identities.attachedIdentities.map(identity => {
                    return {
                        identity_id: identity.id,
                    };
                })
            };
        };
        this.getIdentitiesForUpdate = (proxyAndTagsAndIdentities, stepsInfo) => {
            var _a, _b, _c;
            const initialIdentities = (_c = (_b = (_a = proxyAndTagsAndIdentities === null || proxyAndTagsAndIdentities === void 0 ? void 0 : proxyAndTagsAndIdentities.Proxies_by_pk) === null || _a === void 0 ? void 0 : _a.ProxyIdentities) === null || _b === void 0 ? void 0 : _b.map(identity => identity.identity_id)) === null || _c === void 0 ? void 0 : _c.filter(id => !!id);
            const toBeUpdatedIdentities = stepsInfo.identities.attachedIdentities.filter(identity => { var _a; return String(identity.id) !== ((_a = stepsInfo.identities.defaultIdentity) === null || _a === void 0 ? void 0 : _a.value); }).map(identity => identity.id);
            // the below makes a difference of what is present in initialIdentities, but not in toBeUpdatedIdentities
            const toBeDeletedIdentities = initialIdentities.filter(id => !toBeUpdatedIdentities.includes(id));
            const identityObjects = toBeUpdatedIdentities.map((identityID) => {
                return {
                    identity_id: identityID,
                    proxy_id: proxyAndTagsAndIdentities.Proxies_by_pk.id,
                    deleted: false
                };
            });
            toBeDeletedIdentities.map(identityID => {
                identityObjects.push({
                    identity_id: identityID,
                    proxy_id: proxyAndTagsAndIdentities.Proxies_by_pk.id,
                    deleted: true
                });
            });
            return identityObjects;
        };
        this.getProxyUpdateParams = (proxyAndTagsAndIdentities, providersList, stepsInfo) => {
            var _a, _b, _c, _d, _e;
            const providers = providersList.Providers.map(listProvider => {
                const providerSelected = stepsInfo.details.providers.filter(provider => (provider === null || provider === void 0 ? void 0 : provider.value) === String(listProvider.id));
                return Object.assign(Object.assign({}, (stepsInfo.details.id !== 0 ? { proxy_id: stepsInfo.details.id } : {})), { provider_id: listProvider.id, enabled: providerSelected.length === 1 });
            });
            return {
                id: stepsInfo.details.id,
                name: stepsInfo.details.name,
                description: stepsInfo.details.description,
                default_mode: stepsInfo.details.defaultMode.value,
                learning_mode: stepsInfo.details.learningMode,
                default_identity_id: ((_a = stepsInfo.identities.defaultIdentity) === null || _a === void 0 ? void 0 : _a.value) ? parseInt((_b = stepsInfo.identities.defaultIdentity) === null || _b === void 0 ? void 0 : _b.value, 10) : null,
                domain_acls: this.makeProxyDomainACLs(stepsInfo, stepsInfo.details.id),
                allow_noncloud_traffic: stepsInfo.details.nonProviderTraffic,
                debug: ((_c = stepsInfo.details.logLevel) === null || _c === void 0 ? void 0 : _c.value) === 'DEBUG',
                proxy_mode: 'HYBRID',
                providers: providers,
                tags: (_e = (_d = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.tags) === null || _d === void 0 ? void 0 : _d.editorTags) === null || _e === void 0 ? void 0 : _e.filter(tag => !(tag === null || tag === void 0 ? void 0 : tag.markedForRemoval) && !!(tag === null || tag === void 0 ? void 0 : tag.key)).map(tag => {
                    return {
                        key: tag === null || tag === void 0 ? void 0 : tag.key,
                        value: tag === null || tag === void 0 ? void 0 : tag.value
                    };
                }),
                identities: this.getIdentitiesForUpdate(proxyAndTagsAndIdentities, stepsInfo)
            };
        };
        this.getPageName = () => {
            return 'Proxy';
        };
        this.remotes = remotes;
        this.remoteHandlers = remoteHandlers;
    }
    fetchProxy(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.proxies.get(params);
        });
    }
    listEntity() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.proxies.list();
        });
    }
    deleteEntity(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.proxies.delete({
                id: id
            });
        });
    }
    deleteBulk(ids) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.remotes.proxies.deleteBulk({
                ids: ids
            });
            return {
                success: ((_b = (_a = result === null || result === void 0 ? void 0 : result.update_Proxies) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b.length) === ids.length,
                response: result
            };
        });
    }
    getNonDeletedItems(items, entityID) {
        return items.filter((item) => item.id !== entityID);
    }
    getCredentials(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.credentials.get(params);
        });
    }
    getFormattedEntity(proxy) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
        const identitiesAggregateCount = ((_b = (_a = proxy === null || proxy === void 0 ? void 0 : proxy.Identities_aggregate) === null || _a === void 0 ? void 0 : _a.aggregate) === null || _b === void 0 ? void 0 : _b.count) || 0;
        const humanizedHealthCheckTime = !(proxy.Proxies_by_pk.last_healthcheck_time) ? 'NEVER CHECKED IN' : getLocaleTimestamp(proxy.Proxies_by_pk.last_healthcheck_time);
        return {
            id: proxy.Proxies_by_pk.id,
            key: proxy.Proxies_by_pk.id,
            name: proxy.Proxies_by_pk.name,
            description: proxy.Proxies_by_pk.description,
            status: proxy.Proxies_by_pk.status,
            mode: proxy.Proxies_by_pk.ProxySettings[0].proxy_mode,
            online: isOnline(proxy.Proxies_by_pk.last_healthcheck_time),
            onlineSearchable: isOnline(proxy.Proxies_by_pk.last_healthcheck_time) ? 'ONLINE' : 'OFFLINE',
            tags: proxy.Proxies_by_pk.tags,
            tagsSearchable: ((_c = proxy.Proxies_by_pk) === null || _c === void 0 ? void 0 : _c.tags) && ((_d = proxy.Proxies_by_pk) === null || _d === void 0 ? void 0 : _d.tags.length) > 0
                ? (_e = proxy.Proxies_by_pk) === null || _e === void 0 ? void 0 : _e.tags.map((tag) => {
                    const tagOption = tag;
                    return `${tagOption.key}:${tagOption.value}`;
                })
                : [],
            counterAccepts: (_j = (_h = (_g = (_f = proxy.Proxies_by_pk.Counters_aggregate) === null || _f === void 0 ? void 0 : _f.aggregate) === null || _g === void 0 ? void 0 : _g.sum) === null || _h === void 0 ? void 0 : _h.counter_accepts) !== null && _j !== void 0 ? _j : 0,
            counterDenials: (_o = (_m = (_l = (_k = proxy.Proxies_by_pk.Counters_aggregate) === null || _k === void 0 ? void 0 : _k.aggregate) === null || _l === void 0 ? void 0 : _l.sum) === null || _m === void 0 ? void 0 : _m.counter_denials) !== null && _o !== void 0 ? _o : 0,
            counterLogs: (_s = (_r = (_q = (_p = proxy.Proxies_by_pk.Counters_aggregate) === null || _p === void 0 ? void 0 : _p.aggregate) === null || _q === void 0 ? void 0 : _q.sum) === null || _r === void 0 ? void 0 : _r.counter_notifies) !== null && _s !== void 0 ? _s : 0,
            counterTotal: (_w = (_v = (_u = (_t = proxy.Proxies_by_pk.Counters_aggregate) === null || _t === void 0 ? void 0 : _t.aggregate) === null || _u === void 0 ? void 0 : _u.sum) === null || _v === void 0 ? void 0 : _v.counter_total_request) !== null && _w !== void 0 ? _w : 0,
            lastHealthcheckTime: humanizedHealthCheckTime,
            allowNonCloudTraffic: (_x = proxy.Proxies_by_pk.ProxySettings[0]) === null || _x === void 0 ? void 0 : _x.allow_noncloud_traffic,
            default_mode: this.getLearningModeLabel((_z = (_y = proxy.Proxies_by_pk.ProxySettings[0]) === null || _y === void 0 ? void 0 : _y.default_mode) !== null && _z !== void 0 ? _z : ''),
            itemLoading: false,
            statusUpdated: false,
            websocketError: false,
            created_at: '',
            created_by: '',
            updated_at: '',
            updated_by: '',
            createdByFullName: '',
            updatedByFullName: '',
            identitiesAggregateCount,
            tagString: constructTagString(((_0 = proxy.Proxies_by_pk) === null || _0 === void 0 ? void 0 : _0.tags) || [])
        };
    }
    getFormattedEntities(proxies) {
        const sortedProxies = proxies.Proxies.sort((a, b) => { var _a, _b; return (((_a = b === null || b === void 0 ? void 0 : b.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) < ((_b = a === null || a === void 0 ? void 0 : a.name) === null || _b === void 0 ? void 0 : _b.toLowerCase())) ? 1 : -1; });
        return sortedProxies.map((proxy) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
            const humanizedHealthCheckTime = !(proxy.last_healthcheck_time) ? 'NEVER CHECKED IN' : getLocaleTimestamp(proxy.last_healthcheck_time);
            const proxyEnabledList = ((_a = proxy === null || proxy === void 0 ? void 0 : proxy.ProxyProviders) === null || _a === void 0 ? void 0 : _a.filter(status => status.enabled)) || [];
            return {
                id: proxy.id,
                key: proxy.id,
                name: proxy.name,
                description: proxy.description,
                status: proxy.status,
                mode: proxy.ProxySettings.length > 0 ? proxy.ProxySettings[0].proxy_mode : 'UNKNOWN',
                online: isOnline(proxy.last_healthcheck_time),
                onlineSearchable: isOnline(proxy.last_healthcheck_time) ? 'ONLINE' : 'OFFLINE',
                tags: proxy.tags,
                tagsSearchable: (proxy === null || proxy === void 0 ? void 0 : proxy.tags) && (proxy === null || proxy === void 0 ? void 0 : proxy.tags.length) > 0
                    ? proxy === null || proxy === void 0 ? void 0 : proxy.tags.map((tag) => {
                        const tagOption = tag;
                        return `${tagOption.key}:${tagOption.value}`;
                    })
                    : [],
                counterAccepts: (_e = (_d = (_c = (_b = proxy.Counters_aggregate) === null || _b === void 0 ? void 0 : _b.aggregate) === null || _c === void 0 ? void 0 : _c.sum) === null || _d === void 0 ? void 0 : _d.counter_accepts) !== null && _e !== void 0 ? _e : 0,
                counterDenials: (_j = (_h = (_g = (_f = proxy.Counters_aggregate) === null || _f === void 0 ? void 0 : _f.aggregate) === null || _g === void 0 ? void 0 : _g.sum) === null || _h === void 0 ? void 0 : _h.counter_denials) !== null && _j !== void 0 ? _j : 0,
                counterLogs: (_o = (_m = (_l = (_k = proxy.Counters_aggregate) === null || _k === void 0 ? void 0 : _k.aggregate) === null || _l === void 0 ? void 0 : _l.sum) === null || _m === void 0 ? void 0 : _m.counter_notifies) !== null && _o !== void 0 ? _o : 0,
                counterTotal: (_s = (_r = (_q = (_p = proxy.Counters_aggregate) === null || _p === void 0 ? void 0 : _p.aggregate) === null || _q === void 0 ? void 0 : _q.sum) === null || _r === void 0 ? void 0 : _r.counter_total_request) !== null && _s !== void 0 ? _s : 0,
                lastHealthcheckTime: humanizedHealthCheckTime,
                allowNonCloudTraffic: (_t = proxy.ProxySettings[0]) === null || _t === void 0 ? void 0 : _t.allow_noncloud_traffic,
                default_mode: this.getLearningModeLabel((_w = (_v = (_u = proxy === null || proxy === void 0 ? void 0 : proxy.ProxySettings) === null || _u === void 0 ? void 0 : _u[0]) === null || _v === void 0 ? void 0 : _v.default_mode) !== null && _w !== void 0 ? _w : ''),
                itemLoading: false,
                statusUpdated: false,
                websocketError: false,
                deployments: (proxy === null || proxy === void 0 ? void 0 : proxy.ProxyDeployments) || [],
                providers: (proxy === null || proxy === void 0 ? void 0 : proxy.ProxyProviders) || [],
                providerNames: proxyEnabledList.length > 0 ? (_x = proxyEnabledList.map(provider => { var _a; return (_a = provider === null || provider === void 0 ? void 0 : provider.Provider) === null || _a === void 0 ? void 0 : _a.name; })) === null || _x === void 0 ? void 0 : _x.join(' ') : '[null]',
                tagString: constructTagString((proxy === null || proxy === void 0 ? void 0 : proxy.tags) || []),
                created_at: (_y = proxy === null || proxy === void 0 ? void 0 : proxy.created_at) !== null && _y !== void 0 ? _y : '',
                created_by: (_0 = (_z = proxy === null || proxy === void 0 ? void 0 : proxy.CreatedByUser) === null || _z === void 0 ? void 0 : _z.id) !== null && _0 !== void 0 ? _0 : '',
                updated_at: (_1 = proxy === null || proxy === void 0 ? void 0 : proxy.updated_at) !== null && _1 !== void 0 ? _1 : '',
                updated_by: (_3 = (_2 = proxy === null || proxy === void 0 ? void 0 : proxy.UpdatedByUser) === null || _2 === void 0 ? void 0 : _2.id) !== null && _3 !== void 0 ? _3 : '',
                createdByFullName: (proxy === null || proxy === void 0 ? void 0 : proxy.CreatedByUser) ? `${((_4 = proxy === null || proxy === void 0 ? void 0 : proxy.CreatedByUser) === null || _4 === void 0 ? void 0 : _4.given_name) || ''} ${((_5 = proxy === null || proxy === void 0 ? void 0 : proxy.CreatedByUser) === null || _5 === void 0 ? void 0 : _5.family_name) || ''}` : '',
                updatedByFullName: (proxy === null || proxy === void 0 ? void 0 : proxy.UpdatedByUser) ? `${((_6 = proxy === null || proxy === void 0 ? void 0 : proxy.UpdatedByUser) === null || _6 === void 0 ? void 0 : _6.given_name) || ''} ${((_7 = proxy === null || proxy === void 0 ? void 0 : proxy.UpdatedByUser) === null || _7 === void 0 ? void 0 : _7.family_name) || ''}` : '',
            };
        });
    }
    getEntityForInmemorySelectable(input) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield input;
        });
    }
    createProxy(stepsInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.remotes.proxies.getToken();
            const orgID = getOrgIDFromToken(token);
            const proxyParams = this.getProxyCreateParams(orgID, stepsInfo);
            return this.remotes.proxies.create(proxyParams);
        });
    }
    updateProxy(proxyAndTagsAndIdentities, providersList, stepsInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            const proxyParams = this.getProxyUpdateParams(proxyAndTagsAndIdentities, providersList, stepsInfo);
            return this.remotes.proxies.update(proxyParams);
        });
    }
    createProxyDeployment(proxyParams) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.remotes.proxies.createProxyDeployment(proxyParams);
        });
    }
    getProxyDeploymentConfig(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.proxies.getProxyDeploymentConfig(params);
        });
    }
    updateProxyDeployment(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.proxies.updateProxyDeployment(params);
        });
    }
}
