import React, { useEffect, useState } from 'react';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { useAuth0 } from '@/lib/auth0';
import InmemoryTable from '@/presentation/components/table/inmemory-table';
import { IdentitiesListCardsColumnDefinitions, IdentitiesListPropertyFilterFilteringProperties, IdentitiesListTablePageSizeOptions, IdentitiesListTablePreferences, IdentitiesListTablePropertyFilterFilteringOptions, IdentitiesListTableVisibleContentOptions } from '@/presentation/pages/identities/identities-list-config';
import { useNavigate } from 'react-router-dom';
import { PATH_IDENTITIES, TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import { IdentitiesDeleteStrings, IdentityDeleteShowProp } from '@/presentation/pages/identities/identities-types';
import { makePropertyFilterI18nStrings } from '@/presentation/pages';
import { Button, SpaceBetween } from '@cloudscape-design/components-themed/components';
import { TableEmptyState } from '@/presentation/components/commons/common-components';
import DeleteRecord from '@/presentation/components/table/delete-record';
import { useIsMounted } from '@/presentation/hooks';
import { manageCreatedUpdatedList } from '../common-config';
const IdentitiesList = (props) => {
    const { user } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [identities, setIdentities] = useState([]);
    const navigate = useNavigate();
    const [selectedItems, setSelectedItems] = useState([]);
    const createButtonText = 'Create Identity';
    const collectionResourceName = 'Identities';
    const createOnClick = () => navigate(PATH_IDENTITIES.CREATE);
    const tableEmptyState = React.createElement(TableEmptyState, { resourceName: collectionResourceName, createButtonText: createButtonText, createOnClick: createOnClick });
    const isMounted = useIsMounted();
    const [userFilteringOptions, setUserFilteringOptions] = useState([]);
    const actionButtons = () => {
        return (React.createElement(SpaceBetween, { size: "xs", direction: "horizontal" },
            React.createElement(DeleteRecord, { onDelete: onDeleteConfirm, items: selectedItems, deleteModalStrings: IdentitiesDeleteStrings, itemDeleteShowProp: IdentityDeleteShowProp, entityHandler: props.identitiesHandler, deleteFunctionName: "deleteBulk" }),
            React.createElement(Button, { variant: "primary", onClick: createOnClick, "data-testid": 'create-identity-button-from-list-page' }, createButtonText)));
    };
    const selectionChangeCallback = (items) => {
        setSelectedItems(items);
    };
    const refreshList = () => {
        var _a;
        if (isMounted.current) {
            setLoading(true);
        }
        (_a = props === null || props === void 0 ? void 0 : props.identitiesHandler) === null || _a === void 0 ? void 0 : _a.listEntity().then((entityItems) => {
            var _a;
            const formattedItems = (_a = props.identitiesHandler) === null || _a === void 0 ? void 0 : _a.getFormattedEntities(entityItems);
            const userFilteredOptionsList = manageCreatedUpdatedList(formattedItems);
            if (isMounted.current) {
                setIdentities(formattedItems);
                setUserFilteringOptions(userFilteredOptionsList);
            }
        }).catch((e) => {
            console.error('unable to list entity got error:', e);
        }).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    const onDeleteConfirm = () => {
        setSelectedItems([]);
        refreshList();
    };
    useEffect(() => {
        refreshList();
    }, [user]);
    return (React.createElement(React.Fragment, null,
        React.createElement(InmemoryTable, { stickyHeader: true, loading: loading, loadingText: 'Loading Identities...', selectionType: 'multi', variant: 'full-page', entityHandler: props.identitiesHandler, columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.IDENTITIES_LIST, preferencesStorageKey: PREFERENCES_STORAGE_KEY.IDENTITIES_LIST, columnDefinitions: IdentitiesListCardsColumnDefinitions, defaultPreferences: IdentitiesListTablePreferences, items: identities, resourceName: 'Identities', tableHeaderVariant: 'awsui-h1-sticky', actionButtons: actionButtons(), ariaLabels: {}, tableEmptyState: tableEmptyState, selectionChangeCallback: selectionChangeCallback, filteringAriaLabel: 'Filter Identities', filteringPlaceholder: 'Filter Identities', paginationLabels: paginationLabels, visibleContentOptions: IdentitiesListTableVisibleContentOptions, pageSizeOptions: IdentitiesListTablePageSizeOptions, propertyFilterI18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Identities' }), propertyFilterFilteringOptions: [...IdentitiesListTablePropertyFilterFilteringOptions, ...userFilteringOptions], propertyFilterFilteringProperties: IdentitiesListPropertyFilterFilteringProperties, propertyFilterStorageName: TABLE_FILTERS_SESSION_STORAGE.IDENTITIES_LIST, propertySortingStorageName: TABLE_SORTING_SESSION_STORAGE.IDENTITIES_LIST })));
};
export default IdentitiesList;
