import React, { useEffect, useState } from "react";
import { Badge, Box, Button, ColumnLayout, Container, ContentLayout, Flashbar, Header, SpaceBetween, Tabs, } from '@cloudscape-design/components-themed/components';
import { useNotifications } from "@/presentation/providers/notifications-provider";
import SettingsUserAccess from "@/presentation/pages/settings/user-access/settings-user-access";
import SettingsOrganizationPolicyFunctions from "@/presentation/pages/settings/orgnanization-policy-functions/settings-organization-policy-functions";
import SettingsAuditLogs from "@/presentation/pages/settings/audit-logs/settings-audit-logs";
import SettingsGlobalServices from "@/presentation/pages/settings/global-services/settings-global-services";
import { useAuth0 } from "@/lib/auth0";
import { getAllowedRolesFromToken, getOrgIDFromToken } from "@/lib/auth0/utils";
import { useNavigate } from "react-router-dom";
import { PATH_SETTINGS, PATHS } from '@/presentation/common-utils/constants';
import { useSettingsHandlers } from "@/presentation/providers/settings-handlers-provider";
import { getRandomUUID } from "@/lib/misc/utils";
import { useSplitPanel } from "@/presentation/components/commons/use-split-panel";
import { ROLETYPE, SETTINGS_TABS } from "@/presentation/pages/settings/settings-types";
import { MakeNotificationDestinationsSplitPanel } from "@/presentation/pages/settings/notifications-destinations/settings-notifications-destinations-split-panel";
import SettingsSSO from "@/presentation/pages/settings/sso/settings-sso";
import { MakeAuditLogSplitPanel } from "@/presentation/pages/settings/audit-logs/settings-audit-logs-split-panel";
import Skeleton from "react-loading-skeleton";
import { getUrlTabbedId } from "@/presentation/common-utils/common-utils";
import SettingsCloudTenants from "@/presentation/pages/settings/cloud-tenants/settings-cloud-tenants";
import { MakeCloudTenantSplitPanel } from "@/presentation/pages/settings/cloud-tenants/settings-cloud-tenants-split-panel";
import { makeSplitPanelI18nStrings } from "@/presentation/pages";
import { useIsMounted } from '@/presentation/hooks';
import { ErrorBoundaryContextProvider } from "@/presentation/providers/error-boundary-provider";
import { MakeDomainACLSplitPanel } from '@/presentation/pages/settings/domain-acls/settings-domain-acls-split-panel';
import SettingsDomainAcls from '@/presentation/pages/settings/domain-acls/settings-domain-acls';
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import { breadcrumbsSettings } from '@/presentation/components/commons/breadcrumbs';
import { SplitPanel } from '@/presentation/pages/shared-imports';
import ContentWrapper from '@/presentation/components/content-wrapper';
const SettingsDetails = (props) => {
    const { user, rawToken } = useAuth0();
    const [allowedDomains, setAllowedDomains] = useState([]);
    const [loading, setLoading] = useState(true);
    const { settings } = useSettingsHandlers();
    const settingsHandler = settings;
    const isMounted = useIsMounted();
    useEffect(() => {
        const orgID = getOrgIDFromToken(rawToken);
        settingsHandler.getAllowedDomains({ org_id: orgID }).then((org) => {
            var _a;
            if (((_a = org === null || org === void 0 ? void 0 : org.Organizations) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                const formatted = settingsHandler.getFormattedTokenGroupAllowedDomains(org.Organizations[0].allowed_domains);
                if (isMounted.current) {
                    setAllowedDomains(formatted);
                }
            }
        }).catch(console.error)
            .finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    }, [user]);
    if (loading) {
        return React.createElement(Skeleton, { count: 5 });
    }
    const makeDomainBadges = () => {
        const badges = allowedDomains.map(domain => {
            return React.createElement(Badge, { key: getRandomUUID(), color: 'grey' }, domain === null || domain === void 0 ? void 0 : domain.label);
        });
        return React.createElement(SpaceBetween, { size: 'm', direction: 'horizontal' }, badges);
    };
    const isBetaAccessEnabled = (props === null || props === void 0 ? void 0 : props.isBetaAccess) ? React.createElement("span", { className: "inline-flex items-center rounded-md px-2 py-1 ml-2 text-xs font-medium rules-library-info-badge" }, "BETA ACCESS") : '';
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Details") },
            React.createElement(ColumnLayout, { columns: 4, variant: "text-grid" },
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Organization Name"),
                        React.createElement(Box, null,
                            props.orgName,
                            " ",
                            isBetaAccessEnabled))),
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Organization ID"),
                        React.createElement(Box, null, props.orgID))),
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Allowed Domains"),
                        React.createElement(Box, null, makeDomainBadges()))))));
};
const Settings = ({ resourceId, resourceName }) => {
    const { notifications } = useNotifications();
    const [splitOpen, setSplitOpen] = useState(false);
    const [splitKeyValue, setSplitKeyValue] = useState(1);
    const onSplitPanelToggle = (obj) => {
        setSplitKeyValue(splitKeyValue + 1);
        if (obj === true || obj === false) {
            setSplitOpen(obj);
        }
        else {
            const { detail: { open } } = obj;
            setSplitOpen(open);
        }
    };
    const allTabIds = [
        SETTINGS_TABS.USER_ACCESS,
        SETTINGS_TABS.ORG_POLICY_FUNCTIONS,
        // SETTINGS_TABS.NOTIF_DESTINATIONS,
        SETTINGS_TABS.AUDIT_LOGS,
        SETTINGS_TABS.GLOBAL_SERVICES,
        SETTINGS_TABS.SSO,
        SETTINGS_TABS.DOMAIN_ACLS,
        SETTINGS_TABS.CLOUD_TENANTS
    ];
    const hashValue = resourceId ? resourceName : getUrlTabbedId(allTabIds);
    const [selectedItems, setSelectedItems] = useState([]);
    const [splitPanelI18nStrings, setSplitPanelI18nStrings] = useState(makeSplitPanelI18nStrings());
    const [panelHeader, setPanelHeader] = useState('');
    const [panelBody, setPanelBody] = useState(undefined);
    const [panelPreferences, setPanelPreferences] = useState({ position: 'side' });
    const { splitPanelSize, onSplitPanelResize } = useSplitPanel(selectedItems, splitOpen, onSplitPanelToggle);
    const navigate = useNavigate();
    const { settings } = useSettingsHandlers();
    const settingsHandler = settings;
    const { user, rawToken } = useAuth0();
    const isUserAdmin = getAllowedRolesFromToken(rawToken).includes(ROLETYPE.ADMIN);
    const [orgName, setOrgName] = useState('');
    const [activeOrgID, setActiveOrgID] = useState(0);
    const [isBetaAccess, setIsBetaAccess] = useState(false);
    const [roles, setRoles] = useState([]);
    const [userRoleID, setUserRoleID] = useState(0);
    const [loading, setLoading] = useState(true);
    const [activeTabID, setActiveTabID] = useState(hashValue || SETTINGS_TABS.USER_ACCESS);
    const isMounted = useIsMounted();
    useEffect(() => {
        if (resourceId) {
            const replaceUrl = `${PATHS.SETTINGS}?tabId=${resourceName}`;
            history.replaceState(null, null, replaceUrl);
        }
    }, []);
    const getRoles = () => {
        setLoading(true);
        settingsHandler.listRoles().then((roles) => {
            var _a;
            const allRoles = [];
            (_a = roles === null || roles === void 0 ? void 0 : roles.Roles) === null || _a === void 0 ? void 0 : _a.map(role => {
                var _a;
                allRoles.push({
                    membershipID: 0,
                    roleID: role === null || role === void 0 ? void 0 : role.id,
                    roleName: role === null || role === void 0 ? void 0 : role.role_name
                });
                if ((role === null || role === void 0 ? void 0 : role.role_name) === ROLETYPE.USER) {
                    if (isMounted.current) {
                        setUserRoleID((_a = role === null || role === void 0 ? void 0 : role.id) !== null && _a !== void 0 ? _a : 0);
                    }
                }
            });
            if (isMounted.current) {
                setRoles(allRoles);
            }
        }).catch(console.error).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    const updateOrg = (userMemberships, orgID) => {
        var _a, _b, _c, _d, _e, _f;
        const org = userMemberships.filter(membership => { var _a; return ((_a = membership === null || membership === void 0 ? void 0 : membership.organization) === null || _a === void 0 ? void 0 : _a.id) === orgID; });
        if ((org === null || org === void 0 ? void 0 : org.length) === 1 && isMounted.current) {
            setOrgName((_b = (_a = org[0]) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.company_name);
            setActiveOrgID((_d = (_c = org[0]) === null || _c === void 0 ? void 0 : _c.organization) === null || _d === void 0 ? void 0 : _d.id);
            setIsBetaAccess((_f = (_e = org[0]) === null || _e === void 0 ? void 0 : _e.organization) === null || _f === void 0 ? void 0 : _f.beta_access);
        }
    };
    const handleOnChangePanelPreferences = (preference) => {
        if (preference === null || preference === void 0 ? void 0 : preference.detail) {
            setPanelPreferences(preference.detail);
        }
    };
    const makeSplitPanel = (activeTabId, selectedItems) => {
        switch (activeTabId) {
            case SETTINGS_TABS.AUDIT_LOGS: {
                const { header: auditLogHeader, body: auditLogBody, panelStrings: auditLogPanelStrings } = MakeAuditLogSplitPanel(selectedItems);
                setPanelHeader(auditLogHeader);
                setPanelBody(auditLogBody);
                setSplitPanelI18nStrings(auditLogPanelStrings);
                break;
            }
            case SETTINGS_TABS.NOTIF_DESTINATIONS: {
                const { header: header, body: body, panelStrings: panelStrings } = MakeNotificationDestinationsSplitPanel(selectedItems);
                setPanelHeader(header);
                setPanelBody(body);
                setSplitPanelI18nStrings(panelStrings);
                break;
            }
            case SETTINGS_TABS.CLOUD_TENANTS: {
                const { header: cloudTenantHeader, body: cloudTenantLogBody, panelStrings: cloudTenantPanelStrings } = MakeCloudTenantSplitPanel(selectedItems);
                setPanelHeader(cloudTenantHeader);
                setPanelBody(cloudTenantLogBody);
                if (cloudTenantPanelStrings)
                    setSplitPanelI18nStrings(cloudTenantPanelStrings);
                break;
            }
            case SETTINGS_TABS.DOMAIN_ACLS: {
                const { header: domainACLHeader, body: domainACLBody, panelStrings: domainACLPanelStrings } = MakeDomainACLSplitPanel(selectedItems);
                setPanelHeader(domainACLHeader);
                setPanelBody(domainACLBody);
                if (domainACLPanelStrings) {
                    setSplitPanelI18nStrings(domainACLPanelStrings);
                }
                break;
            }
            default:
                setPanelBody(React.createElement(React.Fragment, null));
                break;
        }
    };
    const onEntityDeleted = () => {
        if (isMounted.current) {
            setSelectedItems([]);
            if (!splitOpen) {
                onSplitPanelToggle(!splitOpen);
            }
            setSplitKeyValue(splitKeyValue + 1);
        }
    };
    const onCloudTenantsSelectedOnChange = (event) => {
        setSplitKeyValue(splitKeyValue + 1);
        setSelectedItems(event.detail.selectedItems);
        makeSplitPanel(SETTINGS_TABS.CLOUD_TENANTS, event.detail.selectedItems);
        //  some state issue when directly opening link, so check before opening
        if (!splitOpen) {
            setSplitOpen(true);
        }
    };
    const onDomainACLSelectedOnChange = (event) => {
        setSplitKeyValue(splitKeyValue + 1);
        setSelectedItems(event.detail.selectedItems);
        makeSplitPanel(SETTINGS_TABS.DOMAIN_ACLS, event.detail.selectedItems);
        //  some state issue when directly opening link, so check before opening
        if (!splitOpen) {
            setSplitOpen(true);
        }
    };
    const onAuditLogsSelectedOnChange = (event) => {
        setSplitKeyValue(splitKeyValue + 1);
        setSelectedItems(event.detail.selectedItems);
        makeSplitPanel(SETTINGS_TABS.AUDIT_LOGS, event.detail.selectedItems);
        setSplitOpen(true);
    };
    const allTabs = [
        {
            label: 'User Access',
            id: SETTINGS_TABS.USER_ACCESS,
            content: React.createElement(ErrorBoundaryContextProvider, null,
                React.createElement(SettingsUserAccess, { roles: roles, userRoleID: userRoleID })),
        },
        {
            label: 'Organization Policy Functions',
            id: SETTINGS_TABS.ORG_POLICY_FUNCTIONS,
            content: React.createElement(ErrorBoundaryContextProvider, null,
                React.createElement(SettingsOrganizationPolicyFunctions, { isUserAdmin: isUserAdmin })),
        },
        // {
        //     label: 'Notification Destinations',
        //     id: SETTINGS_TABS.NOTIF_DESTINATIONS,
        //     content: <ErrorBoundaryContextProvider><SettingsNotificationsDestinations onSelectionChange={onNotificationDestinationsSelectedOnChange} onDelete={onEntityDeleted} selectedItems={selectedItems} splitKeyValue={splitKeyValue}/></ErrorBoundaryContextProvider>,
        // },
        {
            label: 'Audit Logs',
            id: SETTINGS_TABS.AUDIT_LOGS,
            content: React.createElement(ErrorBoundaryContextProvider, null,
                React.createElement(SettingsAuditLogs, { onSelectionChange: onAuditLogsSelectedOnChange, selectedItems: selectedItems, splitKeyValue: splitKeyValue })),
        },
        {
            label: 'Global Services',
            id: SETTINGS_TABS.GLOBAL_SERVICES,
            content: React.createElement(ErrorBoundaryContextProvider, null,
                React.createElement(SettingsGlobalServices, null)),
        },
        {
            label: 'Domain ACLs',
            id: SETTINGS_TABS.DOMAIN_ACLS,
            content: React.createElement(ErrorBoundaryContextProvider, null,
                React.createElement(SettingsDomainAcls, { urlDomainAclID: resourceId && resourceName === SETTINGS_TABS.DOMAIN_ACLS && selectedItems.length < 1 ? resourceId : undefined, onSelectionChange: onDomainACLSelectedOnChange, onDelete: onEntityDeleted, selectedItems: selectedItems, splitKeyValue: splitKeyValue })),
        },
        {
            label: 'SSO',
            id: SETTINGS_TABS.SSO,
            content: React.createElement(ErrorBoundaryContextProvider, null,
                React.createElement(SettingsSSO, null)),
        },
        {
            label: 'Cloud Tenant Structures',
            id: SETTINGS_TABS.CLOUD_TENANTS,
            content: React.createElement(ErrorBoundaryContextProvider, null,
                React.createElement(SettingsCloudTenants, { urlTenantId: resourceId && resourceName === SETTINGS_TABS.CLOUD_TENANTS && selectedItems.length < 1 ? resourceId : undefined, onSelectionChange: onCloudTenantsSelectedOnChange, onDelete: onEntityDeleted, selectedItems: selectedItems, splitKeyValue: splitKeyValue })),
        },
    ];
    const onTabChange = ({ detail: { activeTabId } }) => {
        const navigateUrl = `${PATHS.SETTINGS}?tabId=${activeTabId}`;
        navigate(navigateUrl);
        setActiveTabID(activeTabId);
        setSelectedItems([]);
        makeSplitPanel(activeTabId, []);
    };
    useEffect(() => {
        const tabId = getUrlTabbedId(allTabIds);
        setActiveTabID(tabId || SETTINGS_TABS.USER_ACCESS);
    }, [location === null || location === void 0 ? void 0 : location.href]);
    useEffect(() => {
        const orgID = getOrgIDFromToken(rawToken);
        updateOrg(user.memberships, orgID);
        makeSplitPanel(activeTabID, []);
        getRoles();
    }, [user]);
    const handleSplitPanelResize = (evt) => {
        var _a;
        setSplitKeyValue(splitKeyValue + (((_a = evt === null || evt === void 0 ? void 0 : evt.detail) === null || _a === void 0 ? void 0 : _a.size) || 1));
        onSplitPanelResize(evt);
    };
    if (loading) {
        return React.createElement(Skeleton, { count: 80 });
    }
    return React.createElement(KiverascapeLayout, { breadcrumbMenu: breadcrumbsSettings, splitPanelOpen: splitOpen, onSplitPanelToggle: onSplitPanelToggle, splitPanelSize: splitPanelSize, onSplitPanelResize: handleSplitPanelResize, splitPanelPreferences: panelPreferences, onSplitPanelPreferencesChange: handleOnChangePanelPreferences, splitPanel: panelBody && selectedItems.length > 0 &&
            (React.createElement(SplitPanel, { header: panelHeader, i18nStrings: splitPanelI18nStrings }, panelBody)) || null, content: React.createElement(React.Fragment, null,
            React.createElement(ContentWrapper, { testid: "proxy-create-page" },
                React.createElement(ContentLayout, { header: React.createElement(Header, { variant: "h1", actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                            React.createElement(Button, { variant: "primary", "ata-testid": "click-to-edit-settings", onClick: () => {
                                    navigate(PATH_SETTINGS.EDIT);
                                } }, "Edit")) }, "Settings") },
                    React.createElement(SpaceBetween, { size: "xl" },
                        React.createElement(SettingsDetails, { isAdmin: isUserAdmin, orgID: activeOrgID, orgName: orgName, isBetaAccess: isBetaAccess }),
                        React.createElement(Tabs, { tabs: allTabs, activeTabId: activeTabID, onChange: onTabChange, ariaLabel: "Settings Details" }))))), contentType: "default", notifications: React.createElement(Flashbar, { items: notifications, 
            // @ts-ignore
            stackItems: true }) });
};
export default Settings;
