import React, { useEffect, useState } from 'react';
import { TableEmptyState } from '@/presentation/components/commons/common-components';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { useAuth0 } from '@/lib/auth0';
import InmemoryTable from '@/presentation/components/table/inmemory-table';
import { useNavigate } from 'react-router-dom';
import { ProfilesListCardsColumnDefinitions, ProfilesListPropertyFilterFilteringProperties, ProfilesListTablePageSizeOptions, ProfilesListTablePreferences, ProfilesListTableVisibleContentOptions } from '@/presentation/pages/profiles/profiles-list-config';
import { PATH_PROFILES, TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import { ProfilesDeleteStrings, ProfilesDeleteShowProp } from '@/presentation/pages/profiles/profiles-types';
import { makePropertyFilterI18nStrings } from '@/presentation/pages';
import { Button, SpaceBetween } from '@cloudscape-design/components-themed/components';
import DeleteRecord from '@/presentation/components/table/delete-record';
import { useIsMounted } from '@/presentation/hooks';
import { manageCreatedUpdatedList } from '../common-config';
const ProfilesList = (props) => {
    const { user } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const navigate = useNavigate();
    const [selectedItems, setSelectedItems] = useState([]);
    const createButtonText = 'Create Profile';
    const collectionResourceName = 'Profiles';
    const createOnClick = () => navigate(PATH_PROFILES.CREATE);
    const tableEmptyState = React.createElement(TableEmptyState, { resourceName: collectionResourceName, createButtonText: createButtonText, createOnClick: createOnClick });
    const isMounted = useIsMounted();
    const [userFilteringOptions, setUserFilteringOptions] = useState([]);
    const actionButtons = () => {
        return (React.createElement(SpaceBetween, { size: "xs", direction: "horizontal" },
            React.createElement(DeleteRecord, { onDelete: onDeleteConfirm, items: selectedItems, deleteModalStrings: ProfilesDeleteStrings, itemDeleteShowProp: ProfilesDeleteShowProp, entityHandler: props === null || props === void 0 ? void 0 : props.profilesHandler, deleteFunctionName: "deleteBulk" }),
            React.createElement(Button, { variant: "primary", onClick: createOnClick, "data-testid": 'create-profile-button-from-list-page' }, createButtonText)));
    };
    const selectionChangeCallback = (items) => {
        setSelectedItems(items);
    };
    const refreshList = () => {
        var _a;
        if (isMounted.current) {
            setLoading(true);
        }
        (_a = props === null || props === void 0 ? void 0 : props.profilesHandler) === null || _a === void 0 ? void 0 : _a.listEntity().then((entityItems) => {
            var _a;
            const formattedItems = (_a = props.profilesHandler) === null || _a === void 0 ? void 0 : _a.getFormattedEntities(entityItems);
            const userFilteredOptionsList = manageCreatedUpdatedList(formattedItems);
            if (isMounted.current) {
                setProfiles(formattedItems);
                setUserFilteringOptions(userFilteredOptionsList);
            }
        }).catch((e) => {
            console.error('unable to list entity got error:', e);
        }).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    const onDeleteConfirm = () => {
        setSelectedItems([]);
        refreshList();
    };
    useEffect(() => {
        refreshList();
    }, [user]);
    return (React.createElement(React.Fragment, null,
        React.createElement(InmemoryTable, { stickyHeader: true, loading: loading, loadingText: 'Loading Profiles...', selectionType: 'multi', variant: 'full-page', columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.PROFILES_LIST, preferencesStorageKey: PREFERENCES_STORAGE_KEY.PROFILES_LIST, columnDefinitions: ProfilesListCardsColumnDefinitions, defaultPreferences: ProfilesListTablePreferences, items: profiles, ariaLabels: {}, resourceName: 'Profiles', tableHeaderVariant: 'awsui-h1-sticky', actionButtons: actionButtons(), selectionChangeCallback: selectionChangeCallback, tableEmptyState: tableEmptyState, filteringAriaLabel: 'Filter Profiles', filteringPlaceholder: 'Filter Profiles', paginationLabels: paginationLabels, visibleContentOptions: ProfilesListTableVisibleContentOptions, pageSizeOptions: ProfilesListTablePageSizeOptions, entityHandler: props === null || props === void 0 ? void 0 : props.profilesHandler, propertyFilterI18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Profiles' }), propertyFilterFilteringProperties: ProfilesListPropertyFilterFilteringProperties, propertyFilterFilteringOptions: userFilteringOptions, propertyFilterStorageName: TABLE_FILTERS_SESSION_STORAGE.PROFILES_LIST, propertySortingStorageName: TABLE_SORTING_SESSION_STORAGE.PROFILES_LIST })));
};
export default ProfilesList;
