export var PROXY_MODE_TYPE_WITHOUT_SPACE;
(function (PROXY_MODE_TYPE_WITHOUT_SPACE) {
    PROXY_MODE_TYPE_WITHOUT_SPACE["DEFAULT_BLOCK_PREVENTATIVE"] = "DEFAULTBLOCK";
    PROXY_MODE_TYPE_WITHOUT_SPACE["DEFAULT_ALLOW_BLACKLIST"] = "DEFAULTALLOW";
    PROXY_MODE_TYPE_WITHOUT_SPACE["LEARNING"] = "LEARNING";
})(PROXY_MODE_TYPE_WITHOUT_SPACE || (PROXY_MODE_TYPE_WITHOUT_SPACE = {}));
export var PROXY_MODE_TYPE_LABEL;
(function (PROXY_MODE_TYPE_LABEL) {
    PROXY_MODE_TYPE_LABEL["DEFAULT_BLOCK_PREVENTATIVE"] = "Default Block";
    PROXY_MODE_TYPE_LABEL["DEFAULT_ALLOW_BLACKLIST"] = "Default Allow";
    PROXY_MODE_TYPE_LABEL["LEARNING"] = "Learning Mode";
})(PROXY_MODE_TYPE_LABEL || (PROXY_MODE_TYPE_LABEL = {}));
export var PROXY_MODE_TYPE;
(function (PROXY_MODE_TYPE) {
    PROXY_MODE_TYPE["DEFAULT_BLOCK_PREVENTATIVE"] = "preventative";
    PROXY_MODE_TYPE["DEFAULT_ALLOW_BLACKLIST"] = "blacklist";
    PROXY_MODE_TYPE["LEARNING"] = "learning";
})(PROXY_MODE_TYPE || (PROXY_MODE_TYPE = {}));
export const ProxyDeleteStrings = {
    headerTextSingular: 'Delete Proxy',
    headerTextPlural: ' Delete Proxies',
    modalTextSingular: 'proxy',
    modalTextPlural: 'proxies',
    consentText: 'confirm'
};
export const ProxyDeleteShowProp = 'name';
export var HeaderStrings;
(function (HeaderStrings) {
    HeaderStrings["create"] = "Create Proxy";
    HeaderStrings["edit"] = "Edit Proxy";
})(HeaderStrings || (HeaderStrings = {}));
