var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HandleHttpResponse } from "@/data/protocols/http";
import { KiveraAdminGQLClient, KiveraGQLClient } from "@/infra/http";
export class RemoteUsers {
    constructor(addUserURL, deleteUserURL, resendVerificationURL, client) {
        this.addUserURL = addUserURL;
        this.deleteUserURL = deleteUserURL;
        this.resendVerificationURL = resendVerificationURL;
        this.httpClient = client;
    }
    addUser(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.request({
                url: this.addUserURL,
                method: 'POST',
                body: params
            });
            return HandleHttpResponse(httpResponse);
        });
    }
    deleteUser(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.request({
                url: this.deleteUserURL,
                method: 'DELETE',
                body: params
            });
            return HandleHttpResponse(httpResponse);
        });
    }
    resendVerification(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const httpResponse = yield this.httpClient.request({
                url: this.resendVerificationURL,
                method: 'POST',
                body: params
            });
            return HandleHttpResponse(httpResponse);
        });
    }
    geUserDetails(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).GetUserDetails(params); }));
        });
    }
    list() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).ListUsers(); }));
        });
    }
    listVerifiedUsers(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).ListVerifiedUsers(); }));
        });
    }
    listAllUsers(orgID) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraAdminGQLClient(token).ListOrgMemberships(orgID); }));
        });
    }
    updateUser(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.getToken().then((token) => __awaiter(this, void 0, void 0, function* () { return yield KiveraGQLClient(token).UpdateUserVerificationStatus(params); }));
        });
    }
}
