import React from "react";
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { Box, Header } from "@cloudscape-design/components-themed/components";
import { KiverascapeLayout } from "@/presentation/layouts/kiverascape-layout";
import WelcomeDocumentation from "@/presentation/assets/img/pages/welcome_documentation.svg";
import WelcomeGettingStarted from "@/presentation/assets/img/pages/welcome_getting_started.svg";
import WelcomePolicyReference from "@/presentation/assets/img/pages/welcome_policy_reference.svg";
import { useAuth0 } from '@/lib/auth0';
import { getUrlParamsName } from "@/presentation/common-utils/common-utils";
import { PATHS, PATH_DOCUMENTATION } from "@/presentation/common-utils/constants";
const WelcomeToKivera = () => {
    const { user } = useAuth0();
    const referrer = getUrlParamsName('referrer');
    const docsReferrer = referrer || window.location.pathname;
    const imageItems = [
        {
            id: "WelcomeGettingStarted",
            name: "Getting Started",
            image: WelcomeGettingStarted,
            link: `${PATH_DOCUMENTATION.GETTING_STRATED}?referrer=${docsReferrer}`,
        },
        {
            id: "WelcomePolicyReference",
            name: "Policy Reference",
            image: WelcomePolicyReference,
            link: `${PATH_DOCUMENTATION.POLICY_REFERENCE}?referrer=${docsReferrer}`,
        },
        {
            id: "WelcomeDocumentation",
            name: "Documentation",
            image: WelcomeDocumentation,
            link: `${PATHS.DOCS}?referrer=${docsReferrer}`,
        },
    ];
    const getWelcomeCards = () => {
        return ((imageItems === null || imageItems === void 0 ? void 0 : imageItems.map((item) => {
            return (React.createElement("div", { key: item.id, className: "mt-8 sm:col-span-1 col-span-3 card-item gap-x-20 gap-y-6 text-center border border-gray-500 border-solid rounded-[20px] flex-col justify-start items-center custom-transition flex relative overflow-hidden" },
                React.createElement("div", { className: "h-auto max-w-full", style: { backgroundColor: "inherit", padding: "15%" } },
                    React.createElement("figure", { className: "max-w-lg" },
                        React.createElement("img", { className: "h-auto rounded-lg", src: item === null || item === void 0 ? void 0 : item.image, alt: "image description" }))),
                React.createElement("div", { className: "text-center mx-auto mb-16" },
                    React.createElement("a", { href: item === null || item === void 0 ? void 0 : item.link, target: "_blank", "data-id": "buttonLinks" }, item === null || item === void 0 ? void 0 : item.name))));
        })) || null);
    };
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { content: React.createElement(Box, { margin: {
                    top: "l",
                } },
                React.createElement("div", { className: "welcome-cards mt-16 flex flex-col max-w-screen place-content-center items-center justify-center content-center " },
                    React.createElement("div", { className: "grid grid-cols-3 sm:grid-flow-row gap-x-10 gap-y-10" },
                        React.createElement("div", { className: "display-card-header text-left col-span-3" },
                            React.createElement(Header, { variant: "h1" },
                                React.createElement("span", { className: "header-font", style: { color: "#FFFFFF", marginBottom: "-12px" } },
                                    "Welcome to Kivera",
                                    (user === null || user === void 0 ? void 0 : user.given_name) && `, ${user === null || user === void 0 ? void 0 : user.given_name}!`))),
                        getWelcomeCards()))), contentType: "default", className: "welcome-background" })));
};
export default WelcomeToKivera;
