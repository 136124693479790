import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import React from 'react';
import { Badge } from '@cloudscape-design/components-themed/components';
import { getRandomUUID } from '@/lib/misc/utils';
import { ROLETYPE } from '@/presentation/pages/settings/settings-types';
import gravatarUrl from 'gravatar-url';
import { getLocaleTimestamp } from '@/presentation/common-utils/common-utils';
import { makeSelectRoles } from "@/presentation/pages/settings/user-access/common";
import SettingsUserAccessRoleEditor from "@/presentation/pages/settings/user-access/settings-user-access-role-editor";
export const UserAccessListFilteringProperties = [
    {
        key: 'fullname',
        operators: [':', '!:'],
        propertyLabel: 'Name',
        groupValuesLabel: 'Name values'
    },
    {
        key: 'email',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Email',
        groupValuesLabel: 'Email values'
    },
    {
        key: 'verified',
        operators: [':', '!:', '=', '!='],
        propertyLabel: 'Status',
        groupValuesLabel: 'Status values'
    },
    {
        key: 'roleName',
        operators: ['=', '!='],
        propertyLabel: 'Role',
        groupValuesLabel: 'Role values'
    }
];
export const UserAccessListFilteringOptions = [
    {
        propertyKey: 'roleName',
        value: ROLETYPE.USER
    },
    {
        propertyKey: 'roleName',
        value: ROLETYPE.ADMIN
    },
    {
        propertyKey: 'roleName',
        value: ROLETYPE.READONLY
    },
    {
        propertyKey: 'verified',
        value: 'ACTIVE'
    },
    {
        propertyKey: 'verified',
        value: 'INVITED'
    }
];
export const UserAccessListColumnDefinitions = (roles) => {
    const totalItems = makeSelectRoles(roles);
    return addColumnSortLabels([
        {
            id: 'name',
            sortingField: 'fullname',
            header: 'Name',
            cell: (item) => {
                var _a;
                return (React.createElement("div", { className: "flex items-center gap-2", key: getRandomUUID() },
                    React.createElement("img", { className: "rounded-full", src: gravatarUrl((_a = item === null || item === void 0 ? void 0 : item.email) !== null && _a !== void 0 ? _a : '', { size: 28, default: 'retro' }), key: getRandomUUID(), alt: '', title: (item === null || item === void 0 ? void 0 : item.fullname) || '' }),
                    React.createElement("span", { key: getRandomUUID() }, item === null || item === void 0 ? void 0 : item.fullname)));
            },
            minWidth: 250
        },
        {
            id: 'email',
            sortingField: 'email',
            header: 'Email',
            cell: (item) => {
                return (item === null || item === void 0 ? void 0 : item.email) || '-';
            },
            minWidth: 200
        },
        {
            id: 'verified',
            sortingField: 'verified',
            header: 'Status',
            cell: (item) => {
                return (item === null || item === void 0 ? void 0 : item.verified) ? (React.createElement(Badge, { color: (item === null || item === void 0 ? void 0 : item.verified) === 'ACTIVE' ? 'green' : 'grey' }, item === null || item === void 0 ? void 0 : item.verified)) : '-';
            },
        },
        {
            id: 'created_at',
            sortingField: 'created_at',
            header: 'Created',
            cell: (item) => {
                return getLocaleTimestamp(item === null || item === void 0 ? void 0 : item.created_at);
            },
        },
        {
            id: 'roleName',
            header: 'Role',
            cell: (item) => {
                var _a;
                return (item === null || item === void 0 ? void 0 : item.roleName) ? (React.createElement(Badge, { key: getRandomUUID(), color: 'grey' }, (_a = item === null || item === void 0 ? void 0 : item.roleName) === null || _a === void 0 ? void 0 : _a.toUpperCase())) : '-';
            },
            editConfig: {
                ariaLabel: 'Roles',
                editIconAriaLabel: 'editable',
                errorIconAriaLabel: 'Roles Error',
                editingCell: (item, { currentValue, setValue }) => {
                    return React.createElement(SettingsUserAccessRoleEditor, { item: item, totalItems: totalItems, currentValue: currentValue, setValue: setValue });
                }
            }
        }
    ]);
};
export const UserAccessListTablePreferences = {
    pageSize: 30,
    visibleContent: ['name', 'email', 'verified', 'created_at', 'roleName',],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const UserAccessListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'name', label: 'Name', editable: false },
            { id: 'email', label: 'Email', editable: true },
            { id: 'verified', label: 'Status', editable: true },
            { id: 'created_at', label: 'Created', editable: true },
            { id: 'roleName', label: 'Role', editable: true }
        ]
    }
];
export const UserAccessListTablePageSizeOptions = [
    { value: 10, label: '10 Users' },
    { value: 30, label: '30 Users' },
    { value: 50, label: '50 Users' }
];
