import React from "react";
import { Box, Button, Header, Link, SpaceBetween } from "@cloudscape-design/components-themed/components";
import { getHeaderCounterText, getServerHeaderCounterText } from "@/presentation/components/commons/tableCounterStrings";
const getCounter = (props) => {
    if (props.counter) {
        return props.counter;
    }
    if (!props.totalItems) {
        return null;
    }
    if (props.serverSide) {
        return getServerHeaderCounterText(props.totalItems, props.selectedItems);
    }
    return getHeaderCounterText(props.totalItems, props.selectedItems, props === null || props === void 0 ? void 0 : props.singleSelect);
};
export const InfoLink = ({ id, onFollow, ariaLabel }) => (React.createElement(Link, { variant: "info", id: id, onFollow: onFollow, ariaLabel: ariaLabel }, "Info"));
export const TableHeader = (props) => {
    return React.createElement(Header, { variant: props.variant, counter: getCounter(props), info: props.info, description: props.description, actions: props.actionButtons }, props.title);
};
export const TableNoMatchState = props => (React.createElement(Box, { margin: { vertical: 'xs' }, textAlign: "center", color: "inherit" },
    React.createElement(SpaceBetween, { size: "xxs" },
        React.createElement("div", null,
            React.createElement("b", null, "No matches"),
            React.createElement(Box, { variant: "p", color: "inherit" }, "We can't find a match.")),
        React.createElement(Button, { onClick: props.onClearFilter }, "Clear Filter"))));
export const TableEmptyState = (props) => {
    const { resourceName, createButtonText, createOnClick } = props;
    return (React.createElement(Box, { margin: { vertical: 'xs' }, textAlign: "center", color: "inherit" },
        React.createElement(SpaceBetween, { size: "xxs" },
            React.createElement("div", null,
                React.createElement(Box, { variant: "p", color: "inherit" },
                    "No ",
                    resourceName.toLowerCase(),
                    " associated with this resource.")))));
};
export const makeTableEmptyState = (text) => {
    return (React.createElement(Box, { margin: { vertical: 'xs' }, textAlign: "center", color: "inherit" },
        React.createElement(SpaceBetween, { size: "xxs" },
            React.createElement("div", null,
                React.createElement(Box, { variant: "p", color: "inherit" }, text)))));
};
export const DetailsTableEmptyState = (props) => {
    return (React.createElement(Box, { textAlign: "center", color: "inherit" },
        React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, (props === null || props === void 0 ? void 0 : props.text) || "No data found")));
};
