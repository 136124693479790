import React, { useEffect } from "react";
import { KiverascapeLayout } from "@/presentation/layouts/kiverascape-layout";
import { useAuth0 } from "@/lib/auth0";
const MakeLogout = () => {
    const { logout } = useAuth0();
    useEffect(() => {
        logout();
    }, []);
    return React.createElement(React.Fragment, null,
        React.createElement(KiverascapeLayout, { content: React.createElement(React.Fragment, null,
                React.createElement("p", null, "You are being logged out. Please wait till we redirect you ...")) }));
};
export default MakeLogout;
