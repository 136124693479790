import React from 'react';
import { useIsMounted } from '@/presentation/hooks';
import { makeSelectRoles } from "@/presentation/pages/settings/user-access/common";
import { getRandomUUID } from '@/lib/misc/utils';
import { Select } from '@cloudscape-design/components-themed/components';
const SettingsUserAccessRoleEditor = (props) => {
    var _a, _b;
    const isMounted = useIsMounted();
    let selectedOptions = props === null || props === void 0 ? void 0 : props.currentValue;
    if (!(props === null || props === void 0 ? void 0 : props.currentValue)) {
        const fetchItemRoles = makeSelectRoles(((_a = props === null || props === void 0 ? void 0 : props.item) === null || _a === void 0 ? void 0 : _a.roles) || []);
        selectedOptions = fetchItemRoles.length > 0 ? fetchItemRoles[0] : ((_b = props === null || props === void 0 ? void 0 : props.totalItems) === null || _b === void 0 ? void 0 : _b[0]);
        console.log('acces role ', fetchItemRoles[0], props === null || props === void 0 ? void 0 : props.totalItems);
    }
    return (React.createElement(Select, { key: getRandomUUID(), selectedOption: selectedOptions, onChange: ({ detail }) => {
            if (isMounted.current) {
                props === null || props === void 0 ? void 0 : props.setValue(detail.selectedOption);
            }
        }, options: props === null || props === void 0 ? void 0 : props.totalItems, placeholder: "Select Roles", expandToViewport: true }));
};
export default SettingsUserAccessRoleEditor;
