import React from 'react';
import { ErrorBoundaryContextProvider } from '@/presentation/providers/error-boundary-provider';
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import Dashboard from "@/presentation/pages/dashboard/dashboard";
import { DashboardHandler } from "@/presentation/handlers/pages/dashboard/dashboard-handler";
import { RemoteMetabaseDashboard, RemoteLogsMonitoring } from "@/data/usecases";
import { makeManagementDashboardAPIUrl, makeLogsAPIUrl } from "@/presentation/main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "@/presentation/main/factories/decorators";
import { MonitoringHandler } from "@/presentation/handlers/pages";
const DashboardFactory = (props) => {
    const remotes = {
        dashboardMetabase: new RemoteMetabaseDashboard(makeManagementDashboardAPIUrl(), makeAuthorizeHttpClientDecorator()),
    };
    const dashboardHandler = new DashboardHandler(remotes);
    const monitoringRemotes = {
        monitoring: new RemoteLogsMonitoring(makeLogsAPIUrl(), makeAuthorizeHttpClientDecorator())
    };
    const monitoringHandler = new MonitoringHandler(monitoringRemotes);
    return (React.createElement(ErrorBoundaryContextProvider, null,
        React.createElement(KiverascapeLayout, { content: React.createElement(Dashboard, { dashboardHandler: dashboardHandler, monitoringHandler: monitoringHandler }), contentType: "default", className: "metabase-background" })));
};
export default DashboardFactory;
