import React, { useEffect } from "react";
import { AttributeEditor } from "@cloudscape-design/components-themed/components";
import { getRandomUUID } from "@/lib/misc/utils";
import { isEmpty } from "lodash";
import AttributeKeyValueTextComponent from "@/presentation/components/attribute-editor/attribute-key-value-text-component";
const FormAttributeEditor = (props) => {
    var _a;
    const [items, setItems] = React.useState(props.items);
    const [errorItems, setErrorItems] = React.useState([]);
    const [disableAddButton, setDisableAddButton] = React.useState(((_a = props.items) === null || _a === void 0 ? void 0 : _a.length) === props.itemLimit);
    const [touchedFields, setTouchedFields] = React.useState([]);
    const getErrorItems = (itemsData) => {
        const result = props.attributeKeyValueValidator.validate(itemsData);
        // @ts-ignore
        return !(result === null || result === void 0 ? void 0 : result.valid) ? result === null || result === void 0 ? void 0 : result.errorItems : [];
    };
    const areAllItemsEmpty = () => {
        var _a;
        return ((_a = items.filter(item => isEmpty(String(item === null || item === void 0 ? void 0 : item.key).trim()) && isEmpty(String(item === null || item === void 0 ? void 0 : item.value).trim()))) === null || _a === void 0 ? void 0 : _a.length) === (items === null || items === void 0 ? void 0 : items.length);
    };
    const uniqueArrayById = (array) => {
        const uniqueMap = {};
        array.forEach(item => {
            const keyValue = item.id;
            if (!uniqueMap[keyValue]) {
                uniqueMap[keyValue] = item;
            }
        });
        return Object.values(uniqueMap);
    };
    const checkErrors = (data = items) => {
        let errItems = [];
        if (!areAllItemsEmpty()) {
            errItems = getErrorItems(data);
            setErrorItems(errItems);
        }
        return errItems;
    };
    const checkErrorKeyValues = (data, key, value) => {
        const filterTouchId = touchedFields === null || touchedFields === void 0 ? void 0 : touchedFields.filter(id => id === data.id);
        let errorData = [];
        if ((data[key] === "" && data[value] !== "") || (data[key] !== "" && data[value] === "" && filterTouchId.length > 0)) {
            errorData = getErrorItems([data]);
        }
        const duplicateErrorItems = props.attributeKeyValueValidator.validateDuplicateKeys(items);
        const currErrItems = uniqueArrayById([...duplicateErrorItems, ...errorItems, ...errorData]);
        setErrorItems(currErrItems);
    };
    useEffect(() => {
        checkErrors(items);
    }, [props.recheckErrors]);
    useEffect(() => {
        setItems(props.items);
    }, [props.items]);
    useEffect(() => {
        props.onChange(items);
        setDisableAddButton(items.length === props.itemLimit);
    }, [items]);
    const itemKeyValueOnChange = (text, item, value) => {
        const filtered = items === null || items === void 0 ? void 0 : items.filter(it => (it === null || it === void 0 ? void 0 : it.id) === (item === null || item === void 0 ? void 0 : item.id));
        const errorRefName = value ? 'errorInValue' : 'errorInKey';
        const errorRefCode = value ? 'errorCodeValue' : 'errorCodeKey';
        const itemFieldName = value ? 'value' : 'key';
        const currErrItems = [...errorItems];
        if (filtered === null || filtered === void 0 ? void 0 : filtered.length) {
            filtered[0][itemFieldName] = text;
            // we do this to maintain the order of key values
            const tmpItems = [];
            items === null || items === void 0 ? void 0 : items.forEach(it => {
                if ((it === null || it === void 0 ? void 0 : it.id) !== (item === null || item === void 0 ? void 0 : item.id)) {
                    tmpItems.push(it);
                }
                else {
                    tmpItems.push(filtered[0]);
                }
            });
            if (filtered[0].key === "" && filtered[0].value === "") {
                const filteredItems = (currErrItems === null || currErrItems === void 0 ? void 0 : currErrItems.filter(errItem => (errItem === null || errItem === void 0 ? void 0 : errItem.id) !== (item === null || item === void 0 ? void 0 : item.id))) || [];
                setErrorItems(filteredItems);
            }
            else {
                const filteredItems = (currErrItems === null || currErrItems === void 0 ? void 0 : currErrItems.filter(errItem => ((errItem === null || errItem === void 0 ? void 0 : errItem.id) === (item === null || item === void 0 ? void 0 : item.id)))) || [];
                if (filteredItems.length > 0) {
                    const tempItems = filteredItems[0];
                    tempItems[errorRefName] = false;
                    tempItems[errorRefCode] = undefined;
                    const updateErrItems = uniqueArrayById([...currErrItems, tempItems]);
                    setErrorItems(updateErrItems);
                }
            }
            setItems(tmpItems);
        }
    };
    const getAtributeProps = (item, name, value) => {
        const placeholderText = value ? props.attributeValuePlaceholder : props.attributeKeyPlaceholder;
        return {
            placeholderText,
            keyOrValue: name,
            item,
            items,
            attributeErrorCodeMappings: props.attributeErrorCodeMappings,
            errorItems,
            onChange: (event) => {
                var _a;
                itemKeyValueOnChange((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.value, item, value);
            }
        };
    };
    const AttributeKeyComp = (item) => {
        const attrKeyProps = getAtributeProps(item, "key", false);
        return React.createElement(AttributeKeyValueTextComponent, Object.assign({}, attrKeyProps, { onBlur: () => {
                setTouchedFields([...new Set([...touchedFields, item.id])]);
                checkErrorKeyValues(item, "key", "value");
            } }));
    };
    const AttributeValueComp = (item) => {
        const attrValueProps = getAtributeProps(item, "value", true);
        return React.createElement(AttributeKeyValueTextComponent, Object.assign({}, attrValueProps, { onBlur: () => {
                setTouchedFields([...new Set([...touchedFields, item.id])]);
                checkErrorKeyValues(item, "value", "key");
            } }));
    };
    return (React.createElement("div", { className: 'attribute-editor-container' },
        React.createElement(AttributeEditor, { items: items, definition: [
                {
                    control: AttributeKeyComp
                },
                {
                    control: AttributeValueComp
                }
            ], addButtonText: props.addButtonText, removeButtonText: props.removeButtonText, onAddButtonClick: () => {
                if (items.length < props.itemLimit) {
                    const attr = {
                        id: getRandomUUID(),
                        key: '',
                        value: ''
                    };
                    setItems([...items, attr]);
                }
            }, onRemoveButtonClick: ({ detail: { itemIndex } }) => {
                const tmpItems = [...items];
                tmpItems.splice(itemIndex, 1);
                setItems(tmpItems);
                checkErrors(tmpItems);
            }, disableAddButton: disableAddButton, additionalInfo: props.itemLimit === items.length ? null
                : React.createElement("span", null,
                    "You can add up to ",
                    props.itemLimit - items.length,
                    " more ",
                    (props.itemLimit - items.length) > 1 ? props.itemLimitEntitySuffixPlural : props.itemLimitEntitySuffixSingle), empty: 'No ' + props.itemLimitEntitySuffixPlural + ' associated with the resource.' })));
};
export default FormAttributeEditor;
