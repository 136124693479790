import React, { useEffect, useState } from 'react';
import { Box, Container, FormField, Link, Header, Multiselect, Select, Toggle, SpaceBetween, Button, Icon } from '@cloudscape-design/components-themed/components';
import { getFieldOnChange } from '@/presentation/components';
import { getLogoAsUrl, getProviderDescription } from '@/presentation/pages';
import { useProxiesHandlers } from '@/presentation/providers';
import { capitalizeFirstForcefully } from '@/lib/misc/utils';
import NameAndDescriptionForm from "@/presentation/components/form/name-description-form";
import { useIsMounted } from '@/presentation/hooks';
import { PATH_SETTINGS, PATHS } from '@/presentation/common-utils/constants';
import { SETTINGS_TABS } from '@/presentation/pages/settings/settings-types';
const makeProvidersSelectionOptions = (providers) => {
    var _a, _b;
    return ((_a = providers === null || providers === void 0 ? void 0 : providers.Providers) === null || _a === void 0 ? void 0 : _a.length) > 0
        ? (_b = providers === null || providers === void 0 ? void 0 : providers.Providers) === null || _b === void 0 ? void 0 : _b.map(provider => {
            return {
                label: provider === null || provider === void 0 ? void 0 : provider.name,
                value: String(provider === null || provider === void 0 ? void 0 : provider.id),
                iconUrl: getLogoAsUrl(provider === null || provider === void 0 ? void 0 : provider.name),
                description: getProviderDescription(provider === null || provider === void 0 ? void 0 : provider.name)
            };
        })
        : [];
};
const Providers = (props) => {
    const [selectedOptions, setSelectedOptions] = useState(props.info.details.providers);
    const providersOnChange = getFieldOnChange('multi-select', 'providers', props.onChange);
    return React.createElement("div", { className: "multi-select-container" },
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Providers") },
            React.createElement(FormField, { label: 'Enabled Providers', description: 'Select providers to enable for API access via the proxy. Providers that are unselected will be blocked.' },
                React.createElement(Multiselect, { "data-img-src": 'dddd', selectedOptions: selectedOptions, onChange: ({ detail }) => {
                        setSelectedOptions(detail.selectedOptions);
                        providersOnChange(detail);
                    }, deselectAriaLabel: e => `Remove ${e.label}`, options: makeProvidersSelectionOptions(props.providersList), filteringType: "auto", placeholder: "Choose Providers", selectedAriaLabel: "Selected", "data-testid": "proxy-details-form-choose-providers" }))));
};
const ProxySettings = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    const learningModeOnChange = getFieldOnChange('radio', 'learningMode', props.onChange);
    const logLevelOnChange = getFieldOnChange('select', 'logLevel', props.onChange);
    const { proxies } = useProxiesHandlers();
    const proxiesHandler = proxies;
    const [logLevelOptions, setLogLevelOptions] = useState([]);
    const [logLevelSelected, setLogLevelSelected] = useState(props.info.details.logLevel);
    const [defaultModeOptions, setDefaultModeOptions] = useState([]);
    const [learningModeSelected, setLearningModeSelected] = useState(((_b = (_a = props === null || props === void 0 ? void 0 : props.info) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.learningMode) || ((_e = (_d = (_c = props === null || props === void 0 ? void 0 : props.info) === null || _c === void 0 ? void 0 : _c.details) === null || _d === void 0 ? void 0 : _d.defaultMode) === null || _e === void 0 ? void 0 : _e.value) === "learning");
    const isMounted = useIsMounted();
    const selectableDomainACLs = (_g = (_f = props === null || props === void 0 ? void 0 : props.domainACLs) === null || _f === void 0 ? void 0 : _f.map(acl => {
        var _a;
        return {
            label: acl === null || acl === void 0 ? void 0 : acl.name,
            value: (_a = acl === null || acl === void 0 ? void 0 : acl.id) === null || _a === void 0 ? void 0 : _a.toString()
        };
    })) !== null && _g !== void 0 ? _g : [];
    const [selectedDomainACLOption, setSelectedDomainACLOption] = useState(((_h = props.info.details.domainACLs) === null || _h === void 0 ? void 0 : _h.value) ? props.info.details.domainACLs : undefined);
    useEffect(() => {
        proxiesHandler.remoteHandlers.remoteLogLevelHandler.listEntity().then(levels => {
            const options = levels === null || levels === void 0 ? void 0 : levels.map(level => {
                return {
                    label: capitalizeFirstForcefully(String(level).toLowerCase()),
                    value: level
                };
            });
            if (isMounted.current) {
                setLogLevelOptions(options);
            }
        }).catch(console.error);
        proxiesHandler.remoteHandlers.remoteLearningModeHandler.listEntity().then(modeOptions => {
            if (isMounted.current) {
                setDefaultModeOptions(modeOptions);
            }
        }).catch(console.error);
    }, []);
    const clearDomainACL = () => {
        setSelectedDomainACLOption(undefined);
        props.onChange({
            domainACLs: null
        });
    };
    const toggleNonProviderTraffic = (event) => {
        var _a;
        if (!((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.checked)) {
            setSelectedDomainACLOption(undefined);
            props.onChange({
                domainACLs: null,
                nonProviderTraffic: false
            });
        }
        else {
            props.onChange({
                nonProviderTraffic: true
            });
        }
    };
    const openDomainACLInNewTab = () => {
        const link = `${PATHS.SETTINGS}/${SETTINGS_TABS.DOMAIN_ACLS}/${selectedDomainACLOption === null || selectedDomainACLOption === void 0 ? void 0 : selectedDomainACLOption.value}`;
        window.open(link, '_blank');
    };
    const getDomainAclLabel = () => {
        return React.createElement(React.Fragment, null,
            'Domain ACL',
            React.createElement(Link, { fontSize: "inherit", target: '_blank', href: `${PATH_SETTINGS.DOMAIN_ACLS_CREATE}` },
                ' ( ',
                'Create a Domain ACL',
                React.createElement(Icon, { size: 'normal', name: 'external' }),
                ' )'));
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Proxy Settings") },
            React.createElement(Box, { margin: { bottom: 'l' } },
                React.createElement(SpaceBetween, { size: 's' },
                    React.createElement(FormField, { label: "Default Mode" },
                        React.createElement(Select, { selectedOption: (_k = (_j = props === null || props === void 0 ? void 0 : props.info) === null || _j === void 0 ? void 0 : _j.details) === null || _k === void 0 ? void 0 : _k.defaultMode, onChange: ({ detail }) => {
                                var _a;
                                const isLearningMode = (((_a = detail === null || detail === void 0 ? void 0 : detail.selectedOption) === null || _a === void 0 ? void 0 : _a.value) === 'learning');
                                if (isLearningMode) {
                                    props.onChange({
                                        learningMode: true,
                                        defaultMode: detail.selectedOption
                                    });
                                    setLearningModeSelected(true);
                                }
                                else {
                                    props.onChange({
                                        defaultMode: detail.selectedOption
                                    });
                                }
                            }, options: defaultModeOptions, filteringType: "auto", selectedAriaLabel: "Selected", "data-testid": "proxy-details-form-default-mode-drop-down" })),
                    React.createElement(FormField, { label: "Learning Mode", description: "If Learning Mode is enabled, the proxy will perform rule evaluation but will not block traffic" },
                        React.createElement(Toggle, { onChange: ({ detail }) => {
                                learningModeOnChange(detail);
                                setLearningModeSelected(detail === null || detail === void 0 ? void 0 : detail.checked);
                            }, checked: learningModeSelected, disabled: ((_o = (_m = (_l = props === null || props === void 0 ? void 0 : props.info) === null || _l === void 0 ? void 0 : _l.details) === null || _m === void 0 ? void 0 : _m.defaultMode) === null || _o === void 0 ? void 0 : _o.value) === "learning" })),
                    React.createElement(FormField, { label: "Non-Provider Traffic", description: "Enable other non-provider traffic via the proxy" },
                        React.createElement(Toggle, { onChange: toggleNonProviderTraffic, checked: (_q = (_p = props === null || props === void 0 ? void 0 : props.info) === null || _p === void 0 ? void 0 : _p.details) === null || _q === void 0 ? void 0 : _q.nonProviderTraffic })),
                    React.createElement(FormField, { label: getDomainAclLabel(), description: "When non-provider traffic is enabled, a Domain ACL can be applied to provide fine-grained control of which non-provider domains are allowed. If no Domain ACL is specified, all non-provider domains will be allowed." },
                        React.createElement("div", { className: "flex flex-wrap justify-between" },
                            React.createElement("div", { className: "w-4/6 grow mr-4 py-1" },
                                React.createElement(Select, { selectedOption: (_s = (_r = props === null || props === void 0 ? void 0 : props.info) === null || _r === void 0 ? void 0 : _r.details) === null || _s === void 0 ? void 0 : _s.domainACLs, onChange: ({ detail }) => {
                                        props.onChange({
                                            domainACLs: detail.selectedOption
                                        });
                                        setSelectedDomainACLOption(detail.selectedOption);
                                    }, options: selectableDomainACLs, disabled: !((_u = (_t = props === null || props === void 0 ? void 0 : props.info) === null || _t === void 0 ? void 0 : _t.details) === null || _u === void 0 ? void 0 : _u.nonProviderTraffic), filteringType: "auto", selectedAriaLabel: "Selected", "data-testid": "proxy-details-form-domain-acl-drop-down" })),
                            React.createElement("div", { className: "mr-4 py-1" },
                                React.createElement(Button, { variant: "normal", disabled: !selectedDomainACLOption || !((_w = (_v = props === null || props === void 0 ? void 0 : props.info) === null || _v === void 0 ? void 0 : _v.details) === null || _w === void 0 ? void 0 : _w.nonProviderTraffic), onClick: () => clearDomainACL() }, "Clear")),
                            React.createElement("div", { className: "py-1" },
                                React.createElement(Button, { variant: "normal", disabled: !selectedDomainACLOption || !((_y = (_x = props === null || props === void 0 ? void 0 : props.info) === null || _x === void 0 ? void 0 : _x.details) === null || _y === void 0 ? void 0 : _y.nonProviderTraffic), onClick: () => openDomainACLInNewTab() },
                                    "Open ",
                                    React.createElement(Icon, { size: 'normal', name: 'external' }))))),
                    React.createElement(FormField, { label: 'Log Level' },
                        React.createElement(Select, { selectedOption: logLevelSelected, onChange: ({ detail }) => {
                                logLevelOnChange(detail);
                                setLogLevelSelected(detail.selectedOption);
                            }, options: logLevelOptions, filteringType: "auto", selectedAriaLabel: "Selected", "data-testid": "proxy-details-form-log-level-drop-down" }))))));
};
const ProxiesDetails = (props) => {
    const { nameValidator, descriptionValidator } = useProxiesHandlers();
    const args = Object.assign({ title: "Proxy Details", resourceText: "proxy", nameValidator,
        descriptionValidator }, props);
    return React.createElement(React.Fragment, null,
        React.createElement(SpaceBetween, { size: props.spacing },
            React.createElement(NameAndDescriptionForm, Object.assign({}, args)),
            React.createElement(Providers, Object.assign({}, props)),
            React.createElement(ProxySettings, Object.assign({}, props))));
};
export default ProxiesDetails;
