import React, { useState, useEffect } from 'react';
import { TableEmptyState, TableHeader, TableNoMatchState } from '@/presentation/components/commons/common-components';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { Container, FormField, Header, Pagination, Select, SpaceBetween, Button, PropertyFilter, CollectionPreferences, Table } from '@cloudscape-design/components-themed/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { getFilterCounterText } from '@/presentation/components/commons/tableCounterStrings';
import { getFieldOnChange } from '@/presentation/components';
import { PATH_IDENTITIES, TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '@cloudscape-design/components-themed/components/alert';
import { ProxiesIdentitiesColumnDefinitions, ProxiesIdentitiesDetailsFilteringProperties, ProxiesIdentitiesDetailsTablePageSizeOptions, ProxiesIdentitiesDetailsTablePreferences, ProxiesIdentitiesDetailsTableVisibleContentOptions } from '@/presentation/pages/proxies/proxies-identities-config';
import { autoResizeOption, makePropertyFilterI18nStrings } from '@/presentation/pages';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
import { PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import TableContainer from '@/presentation/components/table/table-container';
import { GetPropertyFilters, GetTableSortingColumn, SetPropertyFilters, SetTableSortingColumn } from '@/presentation/storage/misc-storage';
import { fetchUniquePropertyFilters } from '@/presentation/common-utils/common-utils';
const makeSelectableIdentitiesOptions = (proxyIdentities) => {
    return proxyIdentities === null || proxyIdentities === void 0 ? void 0 : proxyIdentities.map(identity => {
        return {
            label: identity === null || identity === void 0 ? void 0 : identity.name,
            value: String(identity === null || identity === void 0 ? void 0 : identity.id)
        };
    });
};
const ProxiesIdentities = (props) => {
    var _a, _b, _c, _d, _e;
    const navigate = useNavigate();
    const location = useLocation();
    const ref = React.useRef(null);
    const [defaultIdentity, setDefaultIdentity] = useState((_a = props.info.identities.defaultIdentity) === null || _a === void 0 ? void 0 : _a.value);
    const [selectedOption, setSelectedOption] = useState(((_b = props.info.identities.defaultIdentity) === null || _b === void 0 ? void 0 : _b.value) ? props.info.identities.defaultIdentity : null);
    const [selectedItems, setSelectedItems] = useState(props.info.identities.attachedIdentities);
    const [identities] = useState(props.identities);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.PROXIES_CREATE_EDIT_IDENTITIES_LIST, ProxiesIdentitiesDetailsTablePreferences);
    const getSessionPropertyFilter = GetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.PROXIES_FORM_IDENTITIES_LIST);
    const getSessionPropertySorting = GetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.PROXIES_FORM_IDENTITIES_LIST);
    const defaultSortingState = {
        sortingColumn: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.sortingColumn : ProxiesIdentitiesColumnDefinitions('', false)[0],
        isDescending: getSessionPropertySorting ? getSessionPropertySorting === null || getSessionPropertySorting === void 0 ? void 0 : getSessionPropertySorting.isDescending : true
    };
    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(identities, {
        propertyFiltering: {
            defaultQuery: getSessionPropertyFilter,
            filteringProperties: ProxiesIdentitiesDetailsFilteringProperties,
            empty: React.createElement(TableEmptyState, { resourceName: "Identities", createButtonText: "Create Identity", createOnClick: () => navigate(PATH_IDENTITIES.CREATE) }),
            noMatch: (React.createElement(TableNoMatchState, { onClearFilter: () => {
                    actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                } }))
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: defaultSortingState },
        selection: {}
    });
    const [renderCount, setRenderCount] = useState(1);
    const onIdentitiesTableChange = getFieldOnChange('table', 'attachedIdentities', props.onChange);
    const clearDefaultIdentity = () => {
        setSelectedOption(null);
        const removeDefaultItems = selectedItems.filter(item => String(item.id) !== defaultIdentity) || [];
        setSelectedItems(removeDefaultItems);
        setDefaultIdentity(undefined);
        props.onChange({
            defaultIdentity: {},
            attachedIdentities: removeDefaultItems,
        });
    };
    const handleDefaultIdentityChange = (detail) => {
        var _a;
        const { selectedOption: currSelectedOption } = detail;
        const updatedSelectedItems = selectedItems.filter(item => String(item.id) !== defaultIdentity) || [];
        const nonDefaultItems = updatedSelectedItems.filter(item => String(item.id) === currSelectedOption.value) || [];
        if (nonDefaultItems.length < 1) {
            const findRecordToAdd = ((_a = props === null || props === void 0 ? void 0 : props.identities) === null || _a === void 0 ? void 0 : _a.filter((elem) => String(elem.id) === currSelectedOption.value)) || [];
            const newAttachedIdentities = [...updatedSelectedItems, ...findRecordToAdd];
            setSelectedItems(newAttachedIdentities);
        }
        else {
            setSelectedItems(updatedSelectedItems);
        }
        props.onChange({
            attachedIdentities: updatedSelectedItems,
            defaultIdentity: currSelectedOption
        });
        setSelectedOption(currSelectedOption);
        setDefaultIdentity(currSelectedOption.value);
    };
    useEffect(() => {
        var _a;
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            const hashValue = (_a = window.location.hash) === null || _a === void 0 ? void 0 : _a.substring(1);
            if (hashValue === 'identities') {
                document.getElementById('identities').scrollIntoView();
            }
        }
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    const handleFiltersOnChange = ({ detail }) => {
        const filteredDetail = fetchUniquePropertyFilters(detail);
        SetPropertyFilters(TABLE_FILTERS_SESSION_STORAGE.PROXIES_FORM_IDENTITIES_LIST, filteredDetail);
        actions.setPropertyFiltering(filteredDetail);
    };
    return (React.createElement("div", { ref: ref, id: "identities" },
        React.createElement(SpaceBetween, { size: props.spacing },
            React.createElement(Container, null,
                React.createElement(TableContainer, { key: renderCount },
                    React.createElement(Table, Object.assign({}, collectionProps, { columnDefinitions: ProxiesIdentitiesColumnDefinitions(location === null || location === void 0 ? void 0 : location.pathname, false), items: items, selectionType: "multi", visibleColumns: preferences.visibleContent, resizableColumns: (_d = (_c = preferences === null || preferences === void 0 ? void 0 : preferences.custom) === null || _c === void 0 ? void 0 : _c.autoResize) !== null && _d !== void 0 ? _d : false, variant: "embedded", selectedItems: selectedItems, onSelectionChange: event => {
                            setSelectedItems(event.detail.selectedItems);
                            onIdentitiesTableChange(event);
                        }, onSortingChange: ({ detail }) => {
                            SetTableSortingColumn(TABLE_SORTING_SESSION_STORAGE.PROXIES_FORM_IDENTITIES_LIST, detail);
                            actions.setSorting(detail);
                        }, isItemDisabled: (item) => String(item === null || item === void 0 ? void 0 : item.id) === defaultIdentity, pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), filter: React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Identities' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringProperties: ProxiesIdentitiesDetailsFilteringProperties, filteringOptions: [], onChange: handleFiltersOnChange })), header: React.createElement(TableHeader, { title: "Identities", selectedItems: selectedItems, totalItems: identities }), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                                setPreferences(detail);
                                setRenderCount(renderCount + 1);
                            }, pageSizePreference: {
                                title: 'Page Size',
                                options: ProxiesIdentitiesDetailsTablePageSizeOptions
                            }, wrapLinesPreference: {
                                label: 'Wrap Lines',
                                description: 'If checked, lines will be wrapped to display all of the text'
                            }, visibleContentPreference: {
                                title: 'Visible Columns',
                                options: ProxiesIdentitiesDetailsTableVisibleContentOptions
                            } }) }, (((_e = preferences === null || preferences === void 0 ? void 0 : preferences.custom) === null || _e === void 0 ? void 0 : _e.autoResize) ? autoResizeOption : {}), { "data-testid": "proxy-details-identities-form-list-table" })))),
            React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Default Identity") },
                React.createElement(SpaceBetween, { size: "s" },
                    React.createElement(FormField, { label: 'Default Identity', description: "If a default identity is specified, that identity will be used for all unauthenticated requests. If there is no default identity, unauthenticated requests will be blocked." },
                        React.createElement("div", { className: "flex flex-wrap justify-between" },
                            React.createElement("div", { className: "w-5/6 grow mr-5 py-1" },
                                React.createElement(Select, { selectedOption: selectedOption, onChange: ({ detail }) => {
                                        handleDefaultIdentityChange(detail);
                                    }, options: makeSelectableIdentitiesOptions(props.identities), filteringType: "auto", selectedAriaLabel: "Selected", "data-testid": "proxy-details-identities-form-select-default-identity" })),
                            React.createElement("div", { className: "py-1" },
                                React.createElement(Button, { variant: "normal", disabled: !selectedOption, onClick: () => clearDefaultIdentity() }, "Clear")))),
                    React.createElement(Alert, { statusIconAriaLabel: "Warning", type: "error" }, "If your proxy will be publicly accessible it is recommended that you do not set a default identity"))))));
};
export default ProxiesIdentities;
