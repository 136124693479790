import React from 'react';
import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import { Badge } from '@cloudscape-design/components-themed/components';
import { getRandomUUID } from '@/lib/misc/utils';
import { ROLETYPE } from '@/presentation/pages/settings/settings-types';
import { companyNameFilteringProperty } from '@/presentation/pages/common-config';
export const MyProfileOrgTableColumnDefinitions = addColumnSortLabels([
    {
        id: 'companyName',
        sortingField: 'companyName',
        header: 'Organization',
        cell: (item) => item === null || item === void 0 ? void 0 : item.companyName,
    },
    {
        id: 'roleName',
        header: 'Role',
        cell: (item) => {
            var _a;
            return React.createElement(Badge, { key: getRandomUUID(), color: 'grey' }, (_a = item === null || item === void 0 ? void 0 : item.roleName) === null || _a === void 0 ? void 0 : _a.toUpperCase());
        },
    }
]);
export const MyProfileOrgTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'companyName', label: 'Organization', editable: false },
            { id: 'roleName', label: 'Role', editable: true }
        ]
    }
];
export const MyProfileOrgTablePageSizeOptions = [
    { value: 10, label: '10 Organizations' },
    { value: 30, label: '30 Organizations' },
    { value: 50, label: '50 Organizations' }
];
export const MyProfileOrgTablePreferences = {
    pageSize: 30,
    visibleContent: ['companyName', 'roleName'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const MyProfileOrgFilteringProperties = [
    companyNameFilteringProperty,
    {
        key: 'roleName',
        operators: ['=', '!='],
        propertyLabel: 'Role',
        groupValuesLabel: 'Role values'
    }
];
export const MyProfileOrgFilteringOptions = [
    {
        propertyKey: 'roleName',
        value: ROLETYPE.USER
    },
    {
        propertyKey: 'roleName',
        value: ROLETYPE.ADMIN
    },
    {
        propertyKey: 'roleName',
        value: ROLETYPE.READONLY
    }
];
