import React from 'react';
import { useAuth0 } from '@/lib/auth0';
import App from '@/presentation/main/app';
import { ConfigProvider } from "react-avatar";
import OverlayBouncyLoader from '@/presentation/components/loader/overlay-bouncy-loader';
import LoginWithRedirect from "@/presentation/pages/on-boarding/login-with-redirect";
import { AppContextProvider } from '../providers/app-context-provider';
const AppWrapper = ({ appProps }) => {
    const { isAuthenticated, loading } = useAuth0();
    if (loading) {
        return React.createElement(OverlayBouncyLoader, null);
    }
    if (!isAuthenticated) {
        return React.createElement(LoginWithRedirect, null);
    }
    return (React.createElement(ConfigProvider, { colors: ['#6DCCB1', '#F5A35C', '#D2C0A0', '#79AAD9', '#E4A6C7'] },
        React.createElement(AppContextProvider, null,
            React.createElement(App, { appProps: appProps }))));
};
export default AppWrapper;
