import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useIdentitiesHandlers } from "@/presentation/providers";
import { useParams } from "react-router-dom";
import ContentWrapper from "@/presentation/components/content-wrapper";
import { ContentLayout, Header } from "@cloudscape-design/components-themed/components";
import { HeaderStrings } from "@/presentation/pages/identities/identities-types";
import { IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH } from "@/presentation/common-utils/constants";
import IdentitiesForm from "@/presentation/pages/identities/identities-form";
import moment from 'moment';
import { formatExistingTags, getEmptyAttribute } from "@/presentation/pages";
import { useIsMounted } from '@/presentation/hooks';
import { useErrorBoundaryContext } from "@/presentation/providers/error-boundary-provider";
const IdentitiesFormWrapper = (props) => {
    const { setErrorStatus } = useErrorBoundaryContext();
    const [loading, setLoading] = useState(true);
    const [listProfiles, setListProfiles] = useState([]);
    const [identityDetails, setIdentityDetails] = useState(undefined);
    const { identities, profiles } = useIdentitiesHandlers();
    const identitiesHandler = identities;
    const profilesHandler = profiles;
    const params = useParams();
    const [stepInfo, setStepInfo] = useState(undefined);
    const [cloudTenantID, setCloudTenantID] = useState(0);
    const isMounted = useIsMounted();
    const fetchIdentityAndProfiles = (identity) => {
        profilesHandler.listEntity().then((profiles) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
            const identityProfiles = (_e = (_d = (_c = (_b = (_a = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.IdentityProfiles) === null || _c === void 0 ? void 0 : _c.filter(profile => !!(profile === null || profile === void 0 ? void 0 : profile.profile_id))) === null || _d === void 0 ? void 0 : _d.map(profile => profile === null || profile === void 0 ? void 0 : profile.profile_id)) !== null && _e !== void 0 ? _e : [];
            const formattedProfiles = profilesHandler.getSelectableInMemoryFormattedEntities(profiles, identityProfiles);
            if (isMounted.current) {
                setListProfiles(formattedProfiles);
                const existingTags = formatExistingTags((_g = (_f = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.tags);
                const cloudTenantConfig = identitiesHandler.getCloudTenantDetailsFromConfig((_j = (_h = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.config);
                if (cloudTenantConfig === null || cloudTenantConfig === void 0 ? void 0 : cloudTenantConfig.id) {
                    // let's load the label inside the form
                    // because we are fetching the data only there
                    setCloudTenantID(cloudTenantConfig.id);
                }
                let cloudTenantStructureOptions = [];
                if (((_k = cloudTenantConfig === null || cloudTenantConfig === void 0 ? void 0 : cloudTenantConfig.selected) === null || _k === void 0 ? void 0 : _k.length) > 0) {
                    cloudTenantStructureOptions = cloudTenantConfig.selected.map(option => ({ value: option }));
                }
                let extractUserIdentities = [getEmptyAttribute()];
                const userIdentityConfig = identitiesHandler.getUserIdentitiesDetailsFromConfig((_m = (_l = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.config);
                if (userIdentityConfig === null || userIdentityConfig === void 0 ? void 0 : userIdentityConfig.mapping) {
                    const getConfig = [];
                    for (const [key, value] of Object.entries(userIdentityConfig === null || userIdentityConfig === void 0 ? void 0 : userIdentityConfig.mapping)) {
                        getConfig.push({
                            id: `${key}-${value}`,
                            key,
                            value: value[0],
                            existing: true
                        });
                    }
                    extractUserIdentities = getConfig;
                }
                const currentTimestamp = moment().format('YYYYMMDDHHmmss');
                const actualName = (_q = (_p = (_o = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _o === void 0 ? void 0 : _o[0]) === null || _p === void 0 ? void 0 : _p.name) !== null && _q !== void 0 ? _q : '';
                const modifiedName = props.isClone ? `${actualName}-${currentTimestamp}` : actualName;
                const identitiesFormInfo = {
                    details: {
                        id: (_t = (_s = (_r = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _r === void 0 ? void 0 : _r[0]) === null || _s === void 0 ? void 0 : _s.id) !== null && _t !== void 0 ? _t : 0,
                        name: modifiedName,
                        description: (_w = (_v = (_u = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _u === void 0 ? void 0 : _u[0]) === null || _v === void 0 ? void 0 : _v.description) !== null && _w !== void 0 ? _w : '',
                        authenticationMethod: (_z = (_y = (_x = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _x === void 0 ? void 0 : _x[0]) === null || _y === void 0 ? void 0 : _y.identity_type) !== null && _z !== void 0 ? _z : IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH,
                        apiKey: (_2 = identitiesHandler.getAPIKeyFromConfig((_1 = (_0 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _0 === void 0 ? void 0 : _0[0]) === null || _1 === void 0 ? void 0 : _1.config)) !== null && _2 !== void 0 ? _2 : '',
                        cidrRange: (_5 = identitiesHandler.getCIDRRangeFromConfig((_4 = (_3 = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _3 === void 0 ? void 0 : _3[0]) === null || _4 === void 0 ? void 0 : _4.config).join(',')) !== null && _5 !== void 0 ? _5 : '',
                        cloudTenants: cloudTenantStructureOptions,
                        userIdentities: extractUserIdentities
                    },
                    profiles: {
                        attachedProfiles: formattedProfiles.filter(profile => profile.isSelected)
                    },
                    tags: {
                        editorTags: [...existingTags]
                    }
                };
                setStepInfo(identitiesFormInfo);
            }
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        });
    };
    useEffect(() => {
        if ((params === null || params === void 0 ? void 0 : params.identityID) && parseInt(params === null || params === void 0 ? void 0 : params.identityID, 10) !== 0) {
            const id = parseInt(params === null || params === void 0 ? void 0 : params.identityID, 10);
            let identityAndProfiles;
            identitiesHandler.getEntity(id).then((identity) => {
                var _a, _b;
                if (isMounted.current) {
                    if (identity && ((_b = (_a = identity === null || identity === void 0 ? void 0 : identity.Identities) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id) === id) {
                        identityAndProfiles = identity;
                        setIdentityDetails(identity);
                    }
                    else {
                        setErrorStatus(true);
                    }
                }
            }).catch(console.error)
                .finally(() => {
                fetchIdentityAndProfiles(identityAndProfiles);
            });
        }
        else {
            fetchIdentityAndProfiles(undefined);
        }
    }, []);
    if (loading || (props.isEdit && identityDetails === undefined) || !stepInfo) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return React.createElement(ContentWrapper, { testid: "identity-create-edit-page" },
        React.createElement(ContentLayout, { header: React.createElement(Header, { variant: "h1" }, props.isCreate || props.isClone ? HeaderStrings.create : HeaderStrings.edit) },
            React.createElement(IdentitiesForm, { isCreate: props.isCreate, isEdit: props.isEdit, isClone: props.isClone, stepInfo: stepInfo, cloudTenantID: cloudTenantID, identityAndProfiles: identityDetails, profiles: listProfiles })));
};
export default IdentitiesFormWrapper;
